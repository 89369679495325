import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { CommandResult } from 'stores/types';

export type Respondent = 'Trainees' | 'ManualAssignment';
export interface FeedbackTemplateItemDto {
  id: string; //GUID
  friendlyId: string;
  title: string;
  header: string;
  isActive: boolean;
  respondent: Respondent;
  eventTypes: EventTypeItemDto[];
  feedbackQuestions: FeedbackQuestionDto[];
}
export interface CreateFeedbackTemplateItemDto {
  title: string;
  header: string;
  isActive: boolean;
  respondent: Respondent;
  eventTypes: string[];
  feedbackQuestions: string[];
}
export interface ChangeFeedbackTemplateItemDto {
  id: string;
  title: string;
  header: string;
  isActive: boolean;
  respondent: Respondent;
  eventTypes: string[];
  feedbackQuestions: string[];
}

export interface EventTypeItemDto {
  originalId: string;
  name: string;
}
export interface FeedbackQuestionDto {
  questionId: string; // GUID
  text: string;
  friendlyId: string;
}
export interface QuestionDto {
  text: string;
  subtitle: string;
  language: LanguageDto;
}

export type AnswerType = 'Unknown' | 'YesOrNo' | 'Text' | 'Rating';

export class EmptyValidator extends AbstractValidator<any> {}

@repository('@@FEEDBACKSTEMPLATES', 'feedbacks-templates.summary')
export class FeedbacksTemplatesListStore extends DataStore<FeedbackTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-feedback-template';
  retrievePath = 'get-feedback-templates';
  updatePath = 'update-feedback-template';
  deletePath = 'delete-feedback-template';
  hardDeletePath = 'hard-delete-feedback-template';
  retrieveOnePath = 'get-feedback-template';

  protected validate(item: FeedbackTemplateItemDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('FEEDBACKSTEMPLATE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public getTemplateById(id: string): Promise<any> {
    const httpService = container.get(HttpService);
    const result = this.dispatchAsync(this.retrieveOnePath, httpService.get<any>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`));
    return result;
  }
}

@repository('@@FEEDBACKSTEMPLATES', 'feedbacks-templates.wizard')
export class FeedbacksTemplatesStore extends FormStore<FeedbackTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-feedback-template';
  retrievePath = 'get-feedback-templates';
  updatePath = 'update-feedback-template';
  deletePath = 'delete-feedback-template';
  hardDeletePath = 'hard-delete-feedback-template';
  retrieveOnePath = 'get-feedback-template';

  protected validate(item: FeedbackTemplateItemDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('FEEDBACKSTEMPLATE', {
      isBusy: false,
      item: undefined,
      result: undefined,
      status: 'Modified'
    });
  }
  public async getTemplateById(id: string): Promise<any> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(this.retrieveOnePath, httpService.get<any>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`));
    return result.data;
  }
  public async newTemplate() {
    const httpService = container.get(HttpService);
    const { item } = this.state;
    const validation = this.validate(item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const newTemplate = toCreateFeedbackTemplate(item);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreateFeedbackTemplateItemDto, CommandResult<CreateFeedbackTemplateItemDto>>(
        `${this.baseUrl}/${this.createPath}`,
        newTemplate
      )
    );
    return result.data;
  }

  public async changeTemplate() {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const changeTemplate = toChangeFeedbackTemplate(this.state.item);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.put<ChangeFeedbackTemplateItemDto, CommandResult<ChangeFeedbackTemplateItemDto>>(
        `${this.baseUrl}/${this.updatePath}`,
        changeTemplate
      )
    );
    return result.data;
  }
}

export const toCreateFeedbackTemplate = ({
  eventTypes,
  feedbackQuestions,
  id: _0,
  ...rest
}: FeedbackTemplateItemDto): CreateFeedbackTemplateItemDto => {
  return {
    eventTypes: (eventTypes || []).map(({ originalId }) => originalId),
    feedbackQuestions: feedbackQuestions.map(({ questionId }) => questionId),
    ...rest
  };
};

export const toChangeFeedbackTemplate = ({
  eventTypes,
  feedbackQuestions,
  ...rest
}: FeedbackTemplateItemDto): ChangeFeedbackTemplateItemDto => {
  return {
    eventTypes: (eventTypes || []).map(({ originalId }) => originalId),
    feedbackQuestions: feedbackQuestions.map(({ questionId }) => questionId),
    ...rest
  };
};
