import { ItemResult, SaSTokenInfoDto, AzureStorageUploadResult, FileInfo, Message, ImageInfo } from 'stores/types';
import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import * as AzureStorageNpm from 'azure-storage';
import { DateTimeService } from 'services/datetime-service';
import { EventDocumentUploadResult } from 'stores/events/event-form-store';
import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

interface MrTheoreticalQuestionBankUploaderState {
  isBusy: boolean;
  result: AzureStorageUploadResult;
}

interface MrTheoreticalQuestionBankDownloaderState {
  isBusy: boolean;
  result: ItemResult<any>;
}

@repository('@@MR_THEORETICAL_TEST_QB', 'mrTheoreticalTestQB.upload')
export class UploadMRTheoreticalQBStore extends ReduxRepository<MrTheoreticalQuestionBankUploaderState> {
  baseUrl = 'events/v1';
  getSaSTokenUrl = 'get-mr-theoretical-question-bank-blob-sas-url';
  downloadTemplateUrl = 'get-mr-theoretical-question-bank-import-template';
  downloadMediaUrl = 'get-theoretical-question-mr-image';
  getEventDocumentSaSTokenPath = 'get-theoretical-question-mr-image-blob-sas-url';

  public SAS_TOKEN_OBTAINED = 'SAS_TOKEN_OBTAINED';
  public FILE_UPLOADED = 'FILE_UPLOADED';
  public TEMPLATE_DOWNLOADED = 'TEMPLATE_DOWNLOADED';
  public ENTITY_VALIDATED = 'ENTITY_VALIDATED';
  public CLEAR_MESSAGES = 'CLEAR_MESSAGES';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.uploadFile.bind(this);
    this.onSaSTokenObtained.bind(this);
    this.onFileUploaded.bind(this);
    this.onTemplateDownloaded.bind(this);
    this.clearMessages.bind(this);

    this.addReducer(this.SAS_TOKEN_OBTAINED, this.onSaSTokenObtained, 'AsyncAction');
    this.addReducer(this.FILE_UPLOADED, this.onFileUploaded, 'Simple');
    this.addReducer(this.TEMPLATE_DOWNLOADED, this.onTemplateDownloaded, 'AsyncAction');
    this.addReducer(this.ENTITY_VALIDATED, this.validateEntity, 'Simple');
    this.addReducer(this.CLEAR_MESSAGES, this.clearMessages, 'Simple');
  }

  public validateEntity = (result: ValidationResult) => ({
    ...this.state,
    result: {
      isSuccess: false,
      items: [],
      messages:
        result == null
          ? [{ propertyName: '', body: 'Something went wrong', level: 'Error' } as Message]
          : result.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
    }
  });

  public clearMsg = () => {
    this.dispatch(this.CLEAR_MESSAGES);
  };
  public clearMessages = () => ({
    ...this.state,
    result: this.state.result == null ? this.state.result : { ...this.state.result, messages: [] }
  });

  public async uploadFile(fileInfo: FileInfo) {
    const { fileName, fileSize } = fileInfo;
    const params = { fileName, fileSize };
    const url = `${this.baseUrl}/${this.getSaSTokenUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.SAS_TOKEN_OBTAINED, httpService.get<ItemResult<SaSTokenInfoDto>>(url, params), fileInfo);
  }

  public uploadEventDocument(fileInfo: FileInfo): Promise<EventDocumentUploadResult> {
    const validationResult = new ValidationResult();
    if (fileInfo.fileSize <= 0 || fileInfo.fileSize > 5000000)
      validationResult.addFailures([{ message: 'File must be less than 5MB', severity: 'Error' } as ValidationFailure]);

    const extension = fileInfo.fileName
      .split('.')
      .pop()
      .toLowerCase();
    const validExtensions = ['png', 'jpg', 'jpeg'];
    if (!validExtensions.includes(extension))
      validationResult.addFailures([
        { message: `Invalid file type. Allowed extensions: ${validExtensions.join(' ')}`, severity: 'Error' } as ValidationFailure
      ]);

    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return Promise.resolve({ isSuccess: false, filePath: null, messages: [] });
    }

    const { fileName, fileSize } = fileInfo;
    const params = { fileName, fileSize };
    const url = `${this.baseUrl}/${this.getEventDocumentSaSTokenPath}`;

    const httpService = container.get<HttpService>(HttpService);

    return new Promise<EventDocumentUploadResult>(resolve => {
      httpService
        .get<ItemResult<SaSTokenInfoDto>>(url, params)
        .then(tokenResult => {
          const tokenDto = tokenResult.data.item;

          const blobService = AzureStorage.Blob.createBlobServiceWithSas(tokenDto.host, tokenDto.saSToken).withFilter(
            new AzureStorageNpm.ExponentialRetryPolicyFilter(5)
          );
          const customBlockSize = fileInfo.fileSize > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
          blobService.singleBlobPutThresholdInBytes = customBlockSize;

          blobService.createBlockBlobFromBrowserFile(
            tokenDto.container,
            tokenDto.filePath,
            fileInfo.content,
            {
              blockSize: customBlockSize,
              metadata: {
                originalName: encodeURIComponent(fileInfo.fileName),
                userId: fileInfo.userId,
                userName: fileInfo.userName,
                timestamp: DateTimeService.toString(DateTimeService.now()),
                dirty: 'true'
              }
            },
            (error: Error, _: AzureStorageNpm.BlobService.BlobResult, response: AzureStorageNpm.ServiceResponse) => {
              const isSuccess = response && response.isSuccessful;
              let messages = [];
              if (!isSuccess) {
                messages = [i18n.t('Something went wrong uploading the file')];
                if (error && error.message) messages.push(error.message);
                else if (response && response.error && response.error.message) messages.push(error.message);

                validationResult.addFailures(messages.map(m => ({ message: m, severity: 'Error' } as ValidationFailure)));
              }

              this.dispatch(this.ENTITY_VALIDATED, validationResult);
              resolve({ isSuccess, filePath: tokenDto.filePath, messages });
            }
          );
        })
        .catch(e => {
          let messages = [i18n.t('Something went wrong getting permission to upload the file')];
          if (e && e.messages) messages = e.messages;
          else if (e && e.message) messages.push(e.message);
          validationResult.addFailures(messages.map(m => ({ message: m, severity: 'Error' } as ValidationFailure)));
          this.dispatch(this.ENTITY_VALIDATED, validationResult);
          resolve({ isSuccess: false, filePath: null, messages });
        });
    });
  }

  public async downloadMedia({ path, title }: ImageInfo) {
    const url = `${this.baseUrl}/${this.downloadMediaUrl}?filePath=${path}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.TEMPLATE_DOWNLOADED, httpService.download(url, title));
  }

  private onSaSTokenObtained = (): AsyncAction<AxiosResponse<ItemResult<SaSTokenInfoDto>>, MrTheoreticalQuestionBankUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: (result: AxiosResponse<ItemResult<SaSTokenInfoDto>>, fileInfo: FileInfo) => {
        let data = result.data.item;
        const blobService = AzureStorage.Blob.createBlobServiceWithSas(data.host, data.saSToken).withFilter(
          new AzureStorageNpm.ExponentialRetryPolicyFilter(5)
        );
        const customBlockSize = fileInfo.fileSize > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        blobService.createBlockBlobFromBrowserFile(
          data.container,
          data.filePath,
          fileInfo.content,
          {
            blockSize: customBlockSize,
            metadata: {
              originalName: encodeURIComponent(fileInfo.fileName),
              userId: fileInfo.userId,
              userName: fileInfo.userName,
              timestamp: DateTimeService.toString(DateTimeService.now())
            }
          },
          (error: Error, _: AzureStorageNpm.BlobService.BlobResult, response: AzureStorageNpm.ServiceResponse) => {
            const res = { error, response };
            this.dispatch(this.FILE_UPLOADED, res);
          }
        );
        return { ...this.state };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };

  protected onFileUploaded = (result: AzureStorageUploadResult): MrTheoreticalQuestionBankUploaderState => {
    return { ...this.state, isBusy: false, result };
  };

  public async downloadTemplate() {
    const url = `${this.baseUrl}/${this.downloadTemplateUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.TEMPLATE_DOWNLOADED, httpService.download(url, 'MR Theoretical Question Bank.xlsx'));
  }

  private onTemplateDownloaded = (): AsyncAction<AxiosResponse<any>, MrTheoreticalQuestionBankUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: () => ({ ...this.state, isBusy: false, result: undefined }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };
}

@repository('@@MR_THEORETICAL_TEST_QB', 'mrTheoreticalTestQB.download')
export class DownloadMRTheoreticalQBStore extends ReduxRepository<MrTheoreticalQuestionBankDownloaderState> {
  baseUrl = 'events/v1';
  initBackgroundDownloadUrl = 'download-mr-theoretical-question-bank-in-background';

  public BACKGROUND_DOWNLOAD_INITIALIZED = 'BACKGROUND_DOWNLOAD_INITIALIZED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.onBackgroundDownloadInitilized.bind(this);

    this.addReducer(this.BACKGROUND_DOWNLOAD_INITIALIZED, this.onBackgroundDownloadInitilized, 'AsyncAction');
  }

  public async initBackgroundDownload() {
    const url = `${this.baseUrl}/${this.initBackgroundDownloadUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.BACKGROUND_DOWNLOAD_INITIALIZED, httpService.post(url, null));
  }

  private onBackgroundDownloadInitilized = (): AsyncAction<AxiosResponse<any>, MrTheoreticalQuestionBankUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: result => {
        ToastComponent({ text: i18n.t('The email has been sent'), type: 'success-toast' });
        return { ...this.state, isBusy: false, result: result.data };
      },
      onError: error => {
        ToastComponent({ text: i18n.t('File export failed'), type: 'error-toast' });
        return {
          ...this.state,
          isBusy: false,
          result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
        };
      }
    };
  };
}
