import { repository } from 'redux-scaffolding-ts';
import { FormStore } from 'stores/formStore';
import {
  ChangeEventValidator,
  ChangeEventDto,
  EventStatus,
  EventStatusValidation,
  EventUserDto,
  EventInstructorRole,
  DateTimePeriod,
  EventDto,
  EventDetailsDto,
  EventInstructorDto,
  EventTrainingDetailsDto,
  TrainingMaterialDto,
  CloseEventDto,
  ChangeEventInstructorDto,
  ChangeEventTrainingDetailsDto,
  ChangeEventDetailsDto,
  ChangeTrainingMaterialDto,
  EventCheckList,
  CreateTrainingMaterialDto,
  CreateEventTrainingDetailsDto,
  CancelEventDto,
  EventSupportDetailsDto,
  ChangeEventSupportDetailsDto,
  ChangeEventStatusValidationDto,
  EventDocumentChangeAction,
  TravelRangeDto
} from './events-store';
import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import { Message, SaSTokenInfoDto, FileInfo, ItemResult } from 'stores/types';
import { container } from 'inversify.config';
import { ItemReference } from 'stores/dataStore';
import { Categories, RequestMachinesDto, RequestStatus, RequestDto } from 'stores/requests/requests-store';
import { MachineListItemViewModel } from 'widgets/items-list/machine-list-item-deprecated';
import { LocationItemReference } from 'widgets/bussiness/location-editor';
import { StudentDto } from 'stores/students/student-model';
import { TrainingMaterialViewModel } from 'site/pages/events/event-form/tabs/documentation/documentation-tab-pane';
import { EventTypeCategory, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import EventService from './event-service';
import {
  CreateEventDto,
  CreateEventDetailsDto,
  CreateRequestMergeEventItemDto,
  EventInstructorRowValidator
} from './creation-wizard-store';
import i18n from 'i18n';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { DateTimeService } from 'services/datetime-service';
import HttpService from 'services/http-service';
import * as AzureStorageNpm from 'azure-storage';
import { getNextStatus } from 'utils/event-utils';
import { IdentityService } from 'services/identity-service';

export interface EventInstructorViewModel {
  location: LocationItemReference;
  user: EventUserDto;
  role: EventInstructorRole;
  period: DateTimePeriod;
  workingDays: number;
  workingHours: number;
  travelDays: TravelRangeDto;
  notValid?: boolean;
  notInDate?: boolean;
  notConflict?: boolean;
  supportPositionId?: string;
  noTravel: boolean;
}

export interface EventDetailsViewModel {
  location: ItemReference;
  priority: ItemReference;
  profession: ItemReference;
  category: Categories;
  machineRelated: boolean;
  nmrCluster: ItemReference;
  nmrFunctionalArea: ItemReference;
  nmrFunctionalSubArea: ItemReference;
  nmrTrainingName: ItemReference;
  pattern: ItemReference;
  machines: MachineListItemViewModel[];
}

export interface EventTrainingViewModel {
  deliveryMethod: ItemReference;
  trainingLevel: ItemReference;
  language: ItemReference;
  customizationData: ItemReference;
}

export interface ParentRequestViewModel {
  id: string;
  desiredEventDuration: string;
  startDate: string;
  endDate: string;
}

export enum EventDocumentViewState {
  Unmodified = 0,
  Added = 10,
  Deleted = 20
}
export interface EventDocumentViewModel {
  id: string;
  path: string;
  title: string;
  mimeType: string;
  state: EventDocumentViewState;
}

export interface EventFormViewModel {
  locked: boolean;
  priorityLevel: string;
  id: string;
  title: string;
  friendlyId: string;
  status: EventStatus;
  endDate: string;
  startDate: string;
  plannedDuration: string;
  calculatedEventDurationHours: number;
  shouldRecalculate: boolean;

  hasRequestDetails: boolean;
  hasEventDetails: boolean;
  hasStudents: boolean;
  studentsMandatory: boolean;
  hasFeedbackDetails: boolean;
  hasPracticalDetails: boolean;
  isDirectEvent: boolean;
  hasInstructors: boolean;
  hasRequests: boolean;
  hasCheckList: boolean;
  hasSupportDetails: boolean;
  parentRequest: ParentRequestViewModel;
  eventType: EventTypeDto;

  eventCreatorId: string;
  eventDetails: EventDetailsViewModel;
  unblockedForeignResourcesForPlannerTFT?: boolean;
  unblockedForeignResourcesForPlannerMTC?: boolean;
  trainingDetails: EventTrainingViewModel;
  statusValidation: EventStatusValidation;

  supportDetails: EventSupportDetailsViewModel;

  studentsRequested: number;
  studentsAssigned: number;
  students: StudentDto[];

  instructors: EventInstructorViewModel[];
  travelDays?: DateTimePeriod;
  pausePeriods: DateTimePeriod[];
  requests: RequestDto[];
  checkLists: any[];

  trainingMaterials: TrainingMaterialViewModel[];

  comment: string;

  warningMessages: string[];

  supportingDocuments: EventDocumentViewModel[];

  allFeedBackFormAnswered: boolean;
  allTheoreticalFormAnswered: boolean;
  allPracticalFormAnswered: boolean;
  fromScheduler?: boolean;
  noTravel: boolean;
  fromSuggestion: boolean;
}

export interface EventSupportDetailsViewModel {
  positionsRequested: number;
  totalCost: number;
  supportPositions: EventSupportPositionsViewModel[];
  newSupportPositions: EventNewSupportPositionsViewModel[];
  isNewSupportPositionModel: boolean;
}

export interface EventNewSupportPositionsViewModel {
  id: string;
  supportPositionRoleId: string;
  positionsRequested: number;
  eventNewModelPositions: EventPositionViewModel[];
  requestEndDate: string;
  requestStartDate: string;
  patternId: string;
  patternName: string;
  machineModels: RequestMachinesDto[];
  requestedManDays: number;
  requestedWorkingManHours: number;
  theoreticalCost: number;
}

export interface SupportPositionMachineModelDto {
  equipmentTypeId: string;
  machineModelId: string;
  machineModelName: string;
  machineRelatedClusterId: string;
  oemId: string;
}

export interface EventSupportPositionsViewModel {
  id: string;
  positionCodeId: string;
  positionCodeName: string;
  positionDescription: string;
  comment: string;
  positionsRequested: number;
  eventPositions: EventPositionViewModel[];
  requestEndDate: string;
  requestStartDate: string;
}
export interface EventPositionViewModel {
  userId: string;
  user: any;
  startDate: string;
  endDate: string;
  actualDays: string;
  actualHours: string;
  actualCost: string;
  travelDateFrom: string;
  travelDateTo: string;
  userLocationId: string;
  plannedDays?: number;
}

export interface EventDocumentUploadResult {
  isSuccess: boolean;
  messages: string[];
  filePath: string;
}

class EventFormViewModelValidator extends ExtendedAbstractValidator<EventFormViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .fulfills(
        ({ supportDetails }) =>
          supportDetails?.positionsRequested ===
          (supportDetails?.supportPositions || []).reduce((prev, { eventPositions }) => (eventPositions || []).length + prev, 0)
      )
      .when(
        x =>
          getNextStatus(x) &&
          getNextStatus(x).toString() === 'Closed' &&
          x.hasSupportDetails &&
          !x.supportDetails?.isNewSupportPositionModel
      )
      .withFailureMessage(i18n.t('Number of support positions filled does not match with the requested ones'));

    this.validateIf(x => x)
      .fulfills(
        ({ supportDetails }) =>
          (supportDetails?.newSupportPositions || []).reduce(
            (prev, { eventNewModelPositions }) => (eventNewModelPositions || []).length + prev,
            0
          ) > 0
      )
      .when(
        x =>
          getNextStatus(x) && getNextStatus(x).toString() === 'Closed' && x.hasSupportDetails && x.supportDetails?.isNewSupportPositionModel
      )
      .withFailureMessage(i18n.t('Number of support positions filled does not match with the requested ones'));

    this.validateIf(x => x)
      .fulfills(({ supportDetails }) => (supportDetails?.totalCost || 0) >= 0)
      .when(x => getNextStatus(x) && getNextStatus(x).toString() === 'Closed' && x.hasSupportDetails)
      .withFailureMessage(i18n.t('Total Costs of support details should be greater or equal to 0'));

    this.validateIf(x => x)
      .fulfills(
        ({ supportDetails, startDate, endDate }) =>
          (supportDetails.supportPositions || []).length > 0 &&
          supportDetails.supportPositions.every(
            item =>
              item &&
              new SupportPositionValidator(startDate, endDate, supportDetails.supportPositions.indexOf(item), this.addErrors)
                .extendValidate(item)
                .isValid()
          )
      )
      .when(
        x =>
          getNextStatus(x) &&
          getNextStatus(x).toString() === 'Closed' &&
          x.hasSupportDetails &&
          !x.supportDetails?.isNewSupportPositionModel
      )
      .withFailureMessage(i18n.t('At least one support position is wrong'));

    this.validateIf(x => x)
      .fulfills(
        ({ supportDetails, startDate, endDate }) =>
          (supportDetails.newSupportPositions || []).length > 0 &&
          supportDetails.newSupportPositions.every(
            item =>
              item &&
              new NewSupportPositionValidator(startDate, endDate, supportDetails.newSupportPositions.indexOf(item), this.addErrors)
                .extendValidate(item)
                .isValid()
          )
      )
      .when(
        x =>
          getNextStatus(x) && getNextStatus(x).toString() === 'Closed' && x.hasSupportDetails && x.supportDetails?.isNewSupportPositionModel
      )
      .withFailureMessage(i18n.t('At least one support position is wrong'));

    this.validateIf(t => t)
      .fulfills(x => !x.hasStudents || !x.studentsMandatory || x.studentsAssigned > 0)
      .withFailureMessage(i18n.t('Students Assigned must be greater than 0'));

    this.validateIf(t => t)
      .fulfills(x => !x.hasStudents || !x.studentsMandatory || (x.students && x.students.length <= x.studentsAssigned))
      .withFailureMessage(i18n.t('Students Assigned must be equal to or greater than selected students'));

    this.validateIf(x => x)
      .fulfills(x => !x.shouldRecalculate)
      .withFailureMessage(i18n.t('Event Dates or Event Duration needs to be recalculated'));

    this.validateIf(t => t)
      .fulfills(
        x => !x.hasStudents || !x.studentsMandatory || (x.students && x.studentsAssigned > 0 && x.studentsAssigned === x.students.length)
      )
      .when(x => getNextStatus(x) && getNextStatus(x).toString() === 'Closed')
      .withFailureMessage(i18n.t('All Students must be assigned before closing the event'));
  }
}

export class SupportPositionValidator extends ExtendedAbstractValidator<EventSupportPositionsViewModel> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Support section at position ${idx + 1}`);
    this.validateIf(x => x)
      .isNotEmpty()
      .fulfills(({ positionsRequested, eventPositions }) => positionsRequested && positionsRequested === (eventPositions || []).length)
      .withFailureMessage(`${preffix}: ${i18n.t('Number of support positions does not match with the requested ones')}`);

    this.validateIf(x => x.eventPositions)
      .fulfills(
        x =>
          (x || []).length > 0 &&
          x.every(item => {
            const itemValidator = new SupportPositionItemValidator(dateFrom, dateTo, idx, this.addErrors).extendValidate(item);
            return item != null && itemValidator.isValid();
          })
      )
      .withFailureMessage(i18n.t(''));
  }
}

export class NewSupportPositionValidator extends ExtendedAbstractValidator<EventNewSupportPositionsViewModel> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    //const preffix = i18n.t(`Support section at position ${idx + 1}`);
    //Removed by owner request to add only a Warning
    // this.validateIf(x => x)
    //   .isNotEmpty()
    //   .fulfills(
    //     ({ eventNewModelPositions, requestedManDays }) =>
    //       requestedManDays && requestedManDays === eventNewModelPositions.reduce((prevVal, acc) => prevVal + Number(acc['plannedDays']), 0)
    //   )
    //   .withFailureMessage(`${preffix}: ${i18n.t('The sum of Actual days is not the same than requested man days')}`);

    this.validateIf(x => x.eventNewModelPositions)
      .fulfills(
        x =>
          (x || []).length > 0 &&
          x.every(item => {
            const itemValidator = new SupportPositionItemValidator(dateFrom, dateTo, idx, this.addErrors).extendValidate(item);
            return item != null && itemValidator.isValid();
          })
      )
      .withFailureMessage(i18n.t(''));
  }
}
export class SupportPositionItemValidator extends ExtendedAbstractValidator<EventPositionViewModel> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Support item at position ${idx + 1}`);
    this.validateIf(x => x.userId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Selected user is invalid')}`);

    this.validateIf(x => x.actualCost)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Actual Cost value is invalid')}`);

    this.validateIf(x => x.actualDays)
      .isNotEmpty()
      .when(user => user != null && canChangePosition(user))
      .withFailureMessage(`${preffix}: ${i18n.t('Actual Days value is invalid')}`);

    this.validateIf(x => x.actualHours)
      .isNotEmpty()
      .when(user => user != null && canChangePosition(user))
      .withFailureMessage(`${preffix}: ${i18n.t('Actual Hours value is invalid')}`);

    this.validateIf(x => x)
      .isNotEmpty()
      .fulfills(
        ({ startDate, endDate }) =>
          startDate && endDate && DateTimeService.toMoment(startDate).isSameOrBefore(DateTimeService.toMoment(endDate), 'day')
      )
      .withFailureMessage(`${preffix}: ${i18n.t('Invalid dates range')}`);

    this.validateIf(x => x.startDate)
      .isNotEmpty()
      .fulfills(start => DateTimeService.toMoment(start).isSameOrAfter(DateTimeService.toMoment(dateFrom), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Start date is out of the range of the event dates')}`);

    this.validateIf(x => x.endDate)
      .isNotEmpty()
      .fulfills(end => DateTimeService.toMoment(end).isSameOrBefore(DateTimeService.toMoment(dateTo), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('End date is out of the range of the event dates')}`);
  }
}

class ChangeWorkingDayAndHourValidator extends ExtendedAbstractValidator<EventFormViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x);
  }
}
class EventInstructorTravelDaysValidator extends ExtendedAbstractValidator<EventInstructorViewModel> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Instructor at position ${idx + 1}`);
    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ noTravel }) => {
        return noTravel;
      })
      .when(
        ({ travelDays }) =>
          !((travelDays?.arrival?.from && travelDays?.arrival?.to) || (travelDays?.departure?.from && travelDays?.departure?.to))
      )
      .withFailureMessage(`${preffix}: ${i18n.t(`Invalid travel days info. Fill all travel days or check "No Travel" option.`)}`);
  }
}

class TravelsDaysOnClonsingValidator extends ExtendedAbstractValidator<EventFormViewModel> {
  constructor(userId: string, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.instructors || [])
          .map((inst, idx) => {
            return { inst, idx };
          })
          .filter(({ inst }) => inst.user.id === userId)
          .all(
            i =>
              i != null &&
              i.inst &&
              new EventInstructorTravelDaysValidator(x.startDate, x.endDate, i.idx, this.addErrors).extendValidate(i.inst).isValid()
          )
      )
      .when(
        x =>
          EventTypeCategory[x.eventType.eventTypeCategory] !== EventTypeCategory.Vacations &&
          x.eventType.instructor.toLocaleLowerCase() !== 'no'
      )
      .withFailureMessage(i18n.t('At least one instructor is wrong.'));
  }
}

class CreateRequestMergeEventItemDtoValidator extends ExtendedAbstractValidator<EventFormViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.instructors || []).all(
          (i, idx) => i != null && new EventInstructorRowValidator(x.startDate, x.endDate, idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .withFailureMessage(i18n.t('At least one instructor is wrong.'));
  }
}

@repository('@@EVENTS', 'events.event-form')
export class EventFormStore extends FormStore<EventFormViewModel> {
  baseUrl = `events/v1`;
  createPath = '';
  retrievePath = '';
  updatePath = 'update-event';
  retrieveOnePath = '';
  getEventDocumentSaSTokenPath = 'get-event-document-blob-sas-url';
  downloadEventDocumentPath = 'get-event-document';

  constructor() {
    super('NEW_EVENTFORM', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  public PlannerAssistantUpdateSuggestion = async (event: EventDto) => {
    const item = buildEventViewModel(event);
    const changeEventDto = toChangeEventDto(item);

    const changeEventDtoValidation = new ChangeEventValidator().extendValidate(changeEventDto);
    const eventFormViewModelValidation = new EventFormViewModelValidator().extendValidate(item);

    if (changeEventDtoValidation.isInvalid() || eventFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...changeEventDtoValidation.getFailures(), ...eventFormViewModelValidation.getFailures()]);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return { isSuccess: false };
    }

    const eventService = container.get(EventService);
    const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.updateEvent(changeEventDto));
    return result.data;
  };

  protected validate(item: EventFormViewModel): ValidationResult {
    const changEventDto = toChangeEventDto(item);
    return new ChangeEventValidator().validate(changEventDto);
  }

  public async previousStatus() {
    const eventService = container.get(EventService);
    const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.previousStatus(this.state.item.id));
    return result.data;
  }

  public async nextStatus() {
    const { item } = this.state;
    const changeEventDtoValidation = this.validate(item);
    const eventFormViewModelValidation = new EventFormViewModelValidator().extendValidate(item);

    if (changeEventDtoValidation.isInvalid() || eventFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...changeEventDtoValidation.getFailures(), ...eventFormViewModelValidation.getFailures()]);

      this.dispatch(this.ENTITY_VALIDATED, validationResult);
    } else {
      const eventService = container.get(EventService);
      const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.nextStatus(this.state.item.id));
      return result.data;
    }
  }

  public async closeEvent(items: ChangeEventInstructorDto[], userId: string) {
    const { item } = this.state;
    let closeEventDto: CloseEventDto = { id: item.id, items };

    const validationWorking = new ChangeWorkingDayAndHourValidator().extendValidate(item);
    const validationTravelDays = new TravelsDaysOnClonsingValidator(userId).extendValidate(item);

    if (validationWorking.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationWorking);
      return {
        isSuccess: false,
        messages: validationWorking
          .getFailures()
          .map(({ propertyName, message, severity }) => ({ propertyName, body: message, level: severity } as Message))
      } as any;
    }
    if (validationTravelDays.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationTravelDays);
      return {
        isSuccess: false,
        messages: validationTravelDays
          .getFailures()
          .map(({ propertyName, message, severity }) => ({ propertyName, body: message, level: severity } as Message))
      } as any;
    }

    const eventService = container.get(EventService);
    const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.closeEvent(closeEventDto));
    return result.data;
  }

  public async validateStatus() {
    const { item } = this.state;
    const changeEventDtoValidation = this.validate(item);
    const eventFormViewModelValidation = new EventFormViewModelValidator().extendValidate(item);

    if (changeEventDtoValidation.isInvalid() || eventFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...changeEventDtoValidation.getFailures(), ...eventFormViewModelValidation.getFailures()]);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages: validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    }

    const eventService = container.get(EventService);
    const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.validateStatus(this.state.item.id));
    return result.data;
  }

  public async validateSupprotDetailsClose() {
    const { item } = this.state;
    const changeEventDtoValidation = this.validate(item);
    const eventFormViewModelValidation = new EventFormViewModelValidator().extendValidate(item);

    if (changeEventDtoValidation.isInvalid() || eventFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...changeEventDtoValidation.getFailures(), ...eventFormViewModelValidation.getFailures()]);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages: validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    }

    return {
      isSuccess: true,
      messages: []
    } as any;
  }

  public async updateEvent() {
    const { item } = this.state;
    const changeEventDto = toChangeEventDto(item);
    const changeEventDtoValidation = new ChangeEventValidator().extendValidate(changeEventDto);
    const eventFormViewModelValidation = new EventFormViewModelValidator().extendValidate(item);

    if (changeEventDtoValidation.isInvalid() || eventFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...changeEventDtoValidation.getFailures(), ...eventFormViewModelValidation.getFailures()]);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return { isSuccess: false };
    }

    const eventService = container.get(EventService);
    const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.updateEvent(changeEventDto));
    return result.data;
  }

  public async cancelEvent(eventRequestsStatus: RequestStatus, rejectReason: string, rejectedById: string, rejectionReasonId: string) {
    const cancelEventDto: CancelEventDto = { id: this.state.item.id, eventRequestsStatus, rejectReason, rejectedById, rejectionReasonId };

    const eventService = container.get(EventService);
    return await this.dispatchAsync(this.ENTITY_SAVE, eventService.cancelEvent(cancelEventDto));
  }

  public async removeRequestFromEvent(requestId: string) {
    const eventService = container.get(EventService);
    return await this.dispatchAsync(this.ENTITY_CHANGED, eventService.removeRequestFromEvent(this.state.item.id, requestId));
  }

  public async createMergeRequest() {
    const validationResult = new CreateRequestMergeEventItemDtoValidator().validate(this.state.item);
    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages: validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    } else {
      const createRequestMerge = toCreateRequestMergeEventItemDto(this.state.item);
      const eventService = container.get(EventService);
      const result = await this.dispatchAsync(this.ENTITY_SAVE, eventService.createMergeRequest(createRequestMerge));
      return result.data;
    }
  }

  public uploadEventDocument(eventId: string, fileInfo: FileInfo): Promise<EventDocumentUploadResult> {
    const validationResult = new ValidationResult();

    if (fileInfo.fileSize <= 0 || fileInfo.fileSize > 5000000)
      validationResult.addFailures([{ message: 'File must be less than 5MB', severity: 'Error' } as ValidationFailure]);

    const extension = fileInfo.fileName
      .split('.')
      .pop()
      .toLowerCase();
    const validExtensions = ['doc', 'docx', 'pdf', 'msg', 'eml'];
    if (!validExtensions.includes(extension))
      validationResult.addFailures([
        { message: `Invalid file type. Allowed extensions: ${validExtensions.join(' ')}`, severity: 'Error' } as ValidationFailure
      ]);

    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return Promise.resolve({ isSuccess: false, filePath: null, messages: [] });
    }

    const { fileName, fileSize } = fileInfo;
    const params = { fileName, fileSize };
    const url = `${this.baseUrl}/${this.getEventDocumentSaSTokenPath}/${eventId}`;

    const httpService = container.get<HttpService>(HttpService);

    return new Promise<EventDocumentUploadResult>(resolve => {
      httpService
        .get<ItemResult<SaSTokenInfoDto>>(url, params)
        .then(tokenResult => {
          const tokenDto = tokenResult.data.item;

          const blobService = AzureStorage.Blob.createBlobServiceWithSas(tokenDto.host, tokenDto.saSToken).withFilter(
            new AzureStorageNpm.ExponentialRetryPolicyFilter(5)
          );
          const customBlockSize = fileInfo.fileSize > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
          blobService.singleBlobPutThresholdInBytes = customBlockSize;

          blobService.createBlockBlobFromBrowserFile(
            tokenDto.container,
            tokenDto.filePath,
            fileInfo.content,
            {
              blockSize: customBlockSize,
              metadata: {
                originalName: encodeURIComponent(fileInfo.fileName),
                userId: fileInfo.userId,
                userName: fileInfo.userName,
                timestamp: DateTimeService.toString(DateTimeService.now()),
                dirty: 'true'
              }
            },
            (error: Error, _: AzureStorageNpm.BlobService.BlobResult, response: AzureStorageNpm.ServiceResponse) => {
              const isSuccess = response && response.isSuccessful;
              let messages = [];
              if (!isSuccess) {
                messages = [i18n.t('Something went wrong uploading the file')];
                if (error && error.message) messages.push(error.message);
                else if (response && response.error && response.error.message) messages.push(error.message);

                validationResult.addFailures(messages.map(m => ({ message: m, severity: 'Error' } as ValidationFailure)));
              }

              this.dispatch(this.ENTITY_VALIDATED, validationResult);
              resolve({ isSuccess, filePath: tokenDto.filePath, messages });
            }
          );
        })
        .catch(e => {
          let messages = [i18n.t('Something went wrong getting permission to upload the file')];
          if (e && e.messages) messages = e.messages;
          else if (e && e.message) messages.push(e.message);
          validationResult.addFailures(messages.map(m => ({ message: m, severity: 'Error' } as ValidationFailure)));
          this.dispatch(this.ENTITY_VALIDATED, validationResult);
          resolve({ isSuccess: false, filePath: null, messages });
        });
    });
  }

  public async downloadEventDocument(eventId: string, docId: string, title: string = undefined) {
    const validationResult = new ValidationResult();
    const url = `${this.baseUrl}/${this.downloadEventDocumentPath}/${eventId}/${docId}`;
    const httpService = container.get<HttpService>(HttpService);
    try {
      await httpService.download(url, title);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return { isSuccess: true };
    } catch (e) {
      let messages = [i18n.t('Something went wrong downloading the file')];
      if (e && e.messages) messages = e.messages;
      else if (e && e.message) messages.push(e.message);
      validationResult.addFailures(messages.map(m => ({ message: m, severity: 'Error' } as ValidationFailure)));
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return { isSuccess: false };
    }
  }
}

export function toChangeEventDto(item: EventFormViewModel): ChangeEventDto {
  return {
    id: item.id,
    locked: item.locked,
    fromSuggestion: item.fromSuggestion,
    status: item.status,
    unblockedForeignResourcesForPlannerMTC: item.unblockedForeignResourcesForPlannerMTC,
    unblockedForeignResourcesForPlannerTFT: item.unblockedForeignResourcesForPlannerTFT,
    eventDetails: toChangeEventDetailsDto(item.eventDetails),
    comments: item.comment,
    endDate: item.endDate,
    eventTitle: item.title,
    eventTrainingDetails: toChangeEventTrainingDetailsDto(item.trainingDetails, item.studentsAssigned, item.students),
    eventType: item.eventType,
    eventTypeId: item.eventType.id,
    friendlyId: item.friendlyId,
    instructors: toChangeInstructorDetailsDto(item.instructors),
    pausePeriods: item.pausePeriods,
    plannedDuration: item.plannedDuration,
    calculatedEventDurationHours: item.calculatedEventDurationHours,
    startDate: item.startDate,
    title: item.title,
    trainingMaterials: toChangeTrainingMaterialsDto(item.trainingMaterials),
    travelDays: item.travelDays,
    supportDetails: toChangeSupportDetailsDto(item.supportDetails),
    hasStudents: item.hasStudents,
    studentsMandatory: item.studentsMandatory,
    eventStatusValidation: toChangeEventStatusValidationDto(item.statusValidation),
    supportingDocuments: (item.supportingDocuments || [])
      .filter(d => d.state !== EventDocumentViewState.Unmodified)
      .map(d => ({ id: d.id, path: d.path, title: d.title, action: EventDocumentChangeAction[d.state.toString()] }))
  };
}

function toChangeEventStatusValidationDto(eventStatusValidation: EventStatusValidation): ChangeEventStatusValidationDto {
  if (!eventStatusValidation) return null;
  return {
    validatedByEmployees: eventStatusValidation.validatedByEmployees,
    validatedByInstructors: eventStatusValidation.validatedByInstructors,
    validatedByPlanners: eventStatusValidation.validatedByPlanners,
    validatedByPlannersMTC: eventStatusValidation.validatedByPlannersMTC,
    validatedByPoC: eventStatusValidation.validatedByPoC,
    validatedByPoCLocations: eventStatusValidation.validatedByPoCLocations,
    validatedByFactoryLead: eventStatusValidation.validatedByFactoryLead || [],
    validatedByFactoryLeadLocations: eventStatusValidation.validatedByFactoryLeadLocations || []
  };
}

const toChangeSupportDetailsDto = (supportDetails: EventSupportDetailsViewModel): ChangeEventSupportDetailsDto => {
  if (!supportDetails) return null;
  const { supportPositions, newSupportPositions, ...rawData } = supportDetails;
  return {
    ...rawData,
    supportPositions: (supportPositions || []).map(({ eventPositions, positionCodeName: _, positionDescription: __, ...data }) => ({
      ...data,
      eventPositions: (eventPositions || []).map(({ user: ___, ...rest }) => ({ ...rest }))
    })),
    newSupportPositions: (newSupportPositions || []).map(({ eventNewModelPositions, ...data }) => ({
      ...data,
      eventNewModelPositions: (eventNewModelPositions || []).map(({ user: ___, ...rest }) => ({ ...rest }))
    }))
  };
};

function toChangeEventDetailsDto(eventDetails: EventDetailsViewModel): ChangeEventDetailsDto {
  if (!eventDetails) return null;

  return {
    category: eventDetails.category,
    isMachineRelated: eventDetails.machineRelated,
    locationId: eventDetails.location ? eventDetails.location.id : null,
    nmrClusterId: eventDetails.nmrCluster ? eventDetails.nmrCluster.id : null,
    nmrFunctionalAreaId: eventDetails.nmrFunctionalArea ? eventDetails.nmrFunctionalArea.id : null,
    nmrFunctionalSubAreaId: eventDetails.nmrFunctionalSubArea ? eventDetails.nmrFunctionalSubArea.id : null,
    nmrTrainingNameId: eventDetails.nmrTrainingName ? eventDetails.nmrTrainingName.id : null,
    patternId: eventDetails.pattern ? eventDetails.pattern.id : null,
    priorityId: eventDetails.priority ? eventDetails.priority.id : null,
    professionId: eventDetails.profession ? eventDetails.profession.id : null,
    requestedMachines: toRequestedMachinesDto(eventDetails.machines)
  };
}

function toRequestedMachinesDto(machines: MachineListItemViewModel[]): RequestMachinesDto[] {
  if (!machines) return [];

  return machines.map(x => ({
    equipmentTypeId: x.equipmentType ? x.equipmentType.id : null,
    equipmentTypeName: x.equipmentType ? x.equipmentType.title : null,
    machineModelId: x.machineModel ? x.machineModel.id : null,
    machineModelName: x.machineModel ? x.machineModel.title : null,
    machineRelatedClusterId: x.cluster ? x.cluster.id : null,
    machineRelatedClusterName: x.cluster ? x.cluster.title : null,
    machineUnitRequestMachines: x.machineUnits,
    oemId: x.oem ? x.oem.id : null,
    oemName: x.oem ? x.oem.title : null,
    plcTypeRequestMachines: x.plcTypes
  }));
}

function toChangeEventTrainingDetailsDto(
  trainingDetails: EventTrainingViewModel,
  studentsAssigned: number,
  students: StudentDto[]
): ChangeEventTrainingDetailsDto {
  return {
    assignedStudentsIds: (students || []).map(x => x.id),
    numStudentsAssigned: studentsAssigned,
    customizationDataId: trainingDetails && trainingDetails.customizationData ? trainingDetails.customizationData.id : null,
    deliveryMethodId: trainingDetails && trainingDetails.deliveryMethod ? trainingDetails.deliveryMethod.id : null,
    languageId: trainingDetails && trainingDetails.language ? trainingDetails.language.id : null,
    trainingLevelId: trainingDetails && trainingDetails.trainingLevel ? trainingDetails.trainingLevel.id : null
  };
}

function toChangeInstructorDetailsDto(instructors: EventInstructorViewModel[]): ChangeEventInstructorDto[] {
  if (!instructors) return [];

  return instructors.map(({ user, location, period, role, workingDays, workingHours, travelDays, supportPositionId, noTravel }) => ({
    instructorId: user?.id || null,
    locationId: location?.id || null,
    period,
    role,
    workingDays,
    workingHours,
    travelDays,
    supportPositionId,
    noTravel
  }));
}

function toChangeTrainingMaterialsDto(trainingMaterials: TrainingMaterialViewModel[]): ChangeTrainingMaterialDto[] {
  if (!trainingMaterials) return [];

  return trainingMaterials.map(x => ({
    link: x.link,
    title: x.title
  }));
}

export function buildEventViewModel(event: EventDto): EventFormViewModel {
  return {
    id: event.id,
    friendlyId: event.friendlyId,
    title: event.title,
    comment: event.comments,
    startDate: event.startDate,
    endDate: event.endDate,
    unblockedForeignResourcesForPlannerMTC: event.unblockedForeignResourcesForPlannerMTC,
    unblockedForeignResourcesForPlannerTFT: event.unblockedForeignResourcesForPlannerTFT,
    eventDetails: buildEventDetailsViewModel(event.eventDetails),
    eventType: event.eventType,
    instructors: buildInstructorsViewModel(event.instructors),
    requests: event.requests,
    eventCreatorId: event.eventCreatorId,
    trainingDetails: buildTrainingDetailsViewModel(event.eventTrainingDetails),
    students: event.eventTrainingDetails && event.eventTrainingDetails.assignedStudents,
    studentsRequested: event.eventTrainingDetails && event.eventTrainingDetails.numStudentsRequested,
    studentsAssigned: event.eventTrainingDetails && event.eventTrainingDetails.numStudentsAssigned,
    status: event.status,
    statusValidation: event.statusValidation,
    plannedDuration: event.plannedDuration,
    calculatedEventDurationHours: event.calculatedEventDurationHours,
    parentRequest: (event.requests || []).firstOrDefault(x => x.id === event.parentRequestId),
    hasCheckList: event.checkLists && event.checkLists.length > 0,
    hasRequestDetails: event.eventType.requestFieldGroups.some(x => x === 'RequestDetails') && !!event.eventDetails,
    hasEventDetails: event.eventType.requestFieldGroups.some(x => x === 'EventDetails') && !!event.eventTrainingDetails,
    hasFeedbackDetails: event.eventType.feedbacks,
    hasPracticalDetails: event.eventType.practicalTest,
    isDirectEvent: !(event.requests && event.requests.length > 0), //!hasRequests
    hasSupportDetails: event.eventType.requestFieldGroups.some(x => x === 'SupportDetails'),
    supportDetails: buildSupportDetailsViewModel(event.supportDetails),
    hasInstructors: event.eventType.instructor !== 'No',
    hasRequests: event.requests && event.requests.length > 0,
    hasStudents: event.eventType.participants,
    studentsMandatory: !event.eventType.participantsOptional,
    trainingMaterials: buildTrainingMaterialsViewModel(event.trainingMaterials),
    warningMessages: [],
    pausePeriods: event.pausePeriods,
    travelDays: event.travelDays,
    checkLists: buildCheckListViewModel(event.checkLists),
    supportingDocuments: (event.supportingDocuments || []).map(d => ({ ...d, path: null, state: EventDocumentViewState.Unmodified })),
    allFeedBackFormAnswered: event.allFeedBackFormAnswered,
    allTheoreticalFormAnswered: event.allTheoreticalFormAnswered,
    allPracticalFormAnswered: event.allPracticalFormAnswered,
    shouldRecalculate: false,
    noTravel: false,
    priorityLevel: event.eventType.priorityLevel,
    locked: event.locked,
    fromSuggestion: event.fromSuggestion
  };
}

export function buildEventViewModelExtended(event: EventDto): EventFormViewModel {
  const eventFormViewModel = buildEventViewModel(event);

  if (eventFormViewModel && eventFormViewModel.trainingDetails && !eventFormViewModel.trainingDetails.trainingLevel)
    eventFormViewModel.trainingDetails.trainingLevel = { id: event.eventTrainingDetails.trainingLevelId, title: '' } as ItemReference;
  return eventFormViewModel;
}

const buildSupportDetailsViewModel = (supportDetails: EventSupportDetailsViewModel): EventSupportDetailsDto => {
  if (!supportDetails) return null;

  const { supportPositions, totalCost, positionsRequested, isNewSupportPositionModel, newSupportPositions } = supportDetails;
  return { supportPositions, totalCost, positionsRequested, isNewSupportPositionModel, newSupportPositions };
};

function buildCheckListViewModel(checkLists: EventCheckList[]) {
  return checkLists;
}

function buildTrainingDetailsViewModel(trainingDetails: EventTrainingDetailsDto): EventTrainingViewModel {
  if (!trainingDetails) return null;

  return {
    customizationData: trainingDetails.customizationData
      ? { id: trainingDetails.customizationData.id, title: trainingDetails.customizationData.name }
      : null,
    deliveryMethod: trainingDetails.deliveryMethod
      ? { id: trainingDetails.deliveryMethod.id, title: trainingDetails.deliveryMethod.name }
      : trainingDetails.deliveryMethodId
      ? { id: trainingDetails.deliveryMethodId, title: '' }
      : null,
    language: trainingDetails.language ? { id: trainingDetails.language.id, title: trainingDetails.language.language } : null,
    trainingLevel: trainingDetails.trainingLevel
      ? { id: trainingDetails.trainingLevel.id, title: trainingDetails.trainingLevel.name }
      : trainingDetails.trainingLevelId
      ? { id: trainingDetails.trainingLevelId, title: '' }
      : null
  };
}

export function buildInstructorsViewModel(eventInstructors: EventInstructorDto[]): EventInstructorViewModel[] {
  return (eventInstructors || []).map(
    ({ location, period, instructor, role, workingDays, workingHours, travelDays, supportPositionId, noTravel }) => ({
      location: location?.id ? { id: location.id, title: location.location } : null,
      period,
      role,
      user: instructor,
      workingDays,
      workingHours,
      travelDays,
      supportPositionId,
      noTravel
    })
  );
}

function buildEventDetailsViewModel(eventDetails: EventDetailsDto): EventDetailsViewModel {
  if (!eventDetails) return null;

  return {
    category: eventDetails.category,
    location: eventDetails.location ? { id: eventDetails.location.id, title: eventDetails.location.location } : null,
    machineRelated: eventDetails.isMachineRelated,
    machines: (eventDetails.requestedMachines || []).map(x => ({
      cluster: x.machineRelatedClusterId ? { id: x.machineRelatedClusterId, title: x.machineRelatedClusterName } : null,
      equipmentType: x.equipmentTypeId ? { id: x.equipmentTypeId, title: x.equipmentTypeName } : null,
      machineModel: x.machineModelId ? { id: x.machineModelId, title: x.machineModelName } : null,
      oem: x.oemId ? { id: x.oemId, title: x.oemName } : null,
      machineUnits: x.machineUnitRequestMachines,
      plcTypes: x.plcTypeRequestMachines
    })),
    nmrCluster: eventDetails.nmrClusterId ? { id: eventDetails.nmrClusterId, title: eventDetails.nmrClusterName } : null,
    nmrFunctionalArea: eventDetails.nmrFunctionalAreaId
      ? { id: eventDetails.nmrFunctionalAreaId, title: eventDetails.nmrFunctionalAreaName }
      : null,
    nmrFunctionalSubArea: eventDetails.nmrFunctionalSubAreaId
      ? { id: eventDetails.nmrFunctionalSubAreaId, title: eventDetails.nmrFunctionalSubAreaName }
      : null,
    nmrTrainingName: eventDetails.nmrTrainingNameId
      ? { id: eventDetails.nmrTrainingNameId, title: eventDetails.nmrTrainingNameName }
      : null,
    pattern: eventDetails.patternId ? { id: eventDetails.patternId, title: eventDetails.patternName } : null,
    priority: eventDetails.priority ? { id: eventDetails.priority.id, title: eventDetails.priority.name } : null,
    profession: eventDetails.profession
      ? { id: eventDetails.profession.id, title: eventDetails.professionId }
      : eventDetails.professionId
      ? { id: eventDetails.professionId, title: '' }
      : null
  };
}

function buildTrainingMaterialsViewModel(trainingMaterials: TrainingMaterialDto[]): TrainingMaterialViewModel[] {
  return (trainingMaterials || []).map(x => ({
    title: x.title,
    link: x.link
  }));
}

export function toCreateRequestMergeEventItemDto(item: EventFormViewModel): CreateRequestMergeEventItemDto {
  return {
    requestIds: (item.requests || []).map(x => x.id),
    newEvent: toCreateEventDto(item)
  };
}

function toCreateEventDto(item: EventFormViewModel): CreateEventDto {
  return {
    locked: false,
    comments: item.comment,
    endDate: item.endDate,
    eventDetails: toCreateEventDetails(item.eventDetails),
    eventTrainingDetails: toCreateTrainingDetailsDto(item.students, item.studentsAssigned, item.trainingDetails),
    eventTypeId: item.eventType.id,
    eventWarnings: (item.warningMessages || []).map(description => ({ description })),
    travelDays: item.travelDays,
    requestIds: (item.requests || []).map(({ id }) => id),
    instructors: item.instructors,
    parentRequestId: item.parentRequest && item.parentRequest.id,
    pausePeriods: item.pausePeriods,
    plannedDuration: item.plannedDuration,
    startDate: item.startDate,
    title: item.title,
    trainingMaterials: toCreateTrainingMaterialsDto(item.trainingMaterials),
    students: item.students,
    eventDuration: item.plannedDuration
  };
}

function toCreateTrainingDetailsDto(
  students: StudentDto[],
  studentsAssigned: number,
  trainingDetails: EventTrainingViewModel
): CreateEventTrainingDetailsDto {
  return {
    assignedStudentIds: (students || []).map(x => x.id),
    numStudentsAssigned: studentsAssigned,
    customizationDataId: trainingDetails && trainingDetails.customizationData ? trainingDetails.customizationData.id : null,
    deliveryMethodId: trainingDetails && trainingDetails.deliveryMethod ? trainingDetails.deliveryMethod.id : null,
    languageId: trainingDetails && trainingDetails.language ? trainingDetails.language.id : null,
    trainingLevelId: trainingDetails && trainingDetails.trainingLevel ? trainingDetails.trainingLevel.id : null
  };
}

function toCreateTrainingMaterialsDto(trainingMaterials: TrainingMaterialViewModel[]): CreateTrainingMaterialDto[] {
  return (trainingMaterials || []).map(x => ({
    link: x.link,
    title: x.title
  }));
}

function toCreateEventDetails(eventDetails: EventDetailsViewModel): CreateEventDetailsDto {
  if (!eventDetails) return null;

  return {
    category: eventDetails.category,
    isMachineRelated: eventDetails.machineRelated,
    locationId: eventDetails.location ? eventDetails.location.id : null,
    nmrClusterId: eventDetails.nmrCluster ? eventDetails.nmrCluster.id : null,
    nmrFunctionalAreaId: eventDetails.nmrFunctionalArea ? eventDetails.nmrFunctionalArea.id : null,
    nmrFunctionalSubAreaId: eventDetails.nmrFunctionalSubArea ? eventDetails.nmrFunctionalSubArea.id : null,
    nmrTrainingNameId: eventDetails.nmrTrainingName ? eventDetails.nmrTrainingName.id : null,
    patternId: eventDetails.pattern ? eventDetails.pattern.id : null,
    priorityId: eventDetails.priority ? eventDetails.priority.id : null,
    professionId: eventDetails.profession ? eventDetails.profession.id : null,
    requestedMachines: toRequestedMachinesDto(eventDetails.machines)
  };
}

function canChangePosition(user: EventPositionViewModel): boolean {
  const identityService = container.get(IdentityService);
  const currentUser = identityService.getUserInfo();
  const isInstructor = IdentityService.isInstructor(identityService.getUserInfo());
  const isAdminPocOrPlanner = IdentityService.isAdminPocOrPlanner(identityService.getUserInfo());

  return isAdminPocOrPlanner || (isInstructor && currentUser && currentUser.userId === user.userId);
}
