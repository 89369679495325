import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { DataStore } from 'stores/dataStore';
import { repository } from 'redux-scaffolding-ts';
import { EmptyValidator } from './feedbacks-templates-store';
import { ProfessionDto } from 'stores/configuration/profiles/professions-store';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { EquipmentTypeDto } from 'stores/configuration/machinery/equipment-types-store';
import { OemDto } from 'stores/configuration/machinery/oems-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import { NMFunctionalAreaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { NMTrainingNameDto } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { NMFunctionalSubareaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { FormStore } from 'stores/formStore';
import { MrPracticalTestQuestionItemDto, NmrPracticalTestQuestionItemDto } from '../questionBank/practical-test-store';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface MrPracticalTemplateDetailsDto {
  clusterId: string;
  cluster: ClusterDto;
  equipmentTypeId: string;
  equipmentType: EquipmentTypeDto;
  oemId: string;
  oem: OemDto;
  machineModelsId: string[];
  machineModels: MachineModelDto[];
  mrPracticalQuestions: MrPracticalTestQuestionItemDto[];
}

export interface NmrPracticalTemplateDetailsDto {
  nmrClusterId: string;
  nmrCluster: NMClusterDto;
  functionalAreaId: string;
  functionalArea: NMFunctionalAreaDto;
  trainingNameId: string;
  trainingName: NMTrainingNameDto;
  functionalSubareaId: string;
  functionalSubArea: NMFunctionalSubareaDto;
  nmrPracticalQuestions: NmrPracticalTestQuestionItemDto[];
}

export interface PracticalTemplateEventTypeItemDto {
  originalId: string;
  name: string;
}

export interface PracticalTemplateItemDto {
  id: string;
  friendlyId: string;
  title: string;
  header: string;
  isActive: boolean;
  isMachineRelated: boolean;
  professionId: string;
  profession: ProfessionDto;
  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
  eventTypes: PracticalTemplateEventTypeItemDto[];
  mrPracticalTemplateDetails: MrPracticalTemplateDetailsDto;
  nmrPracticalTemplateDetails: NmrPracticalTemplateDetailsDto;
}

@repository('@@PRACTICALTEMPLATES', 'practical-templates.summary')
export class PracticalTemplatesStore extends DataStore<PracticalTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = 'get-practical-templates';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'get-practical-template';

  protected validate(item: PracticalTemplateItemDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('PRACTICALTEMPLATE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getTemplateById(id: string): Promise<PracticalTemplateItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<PracticalTemplateItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

export interface CreateMrPracticalTemplateDetailsDto {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelsId: string[];
  mrPracticalQuestions: string[];
}

export interface CreateNmrPracticalTemplateDetailsDto {
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string;
  functionalSubareaId: string;
  nmrPracticalQuestions: string[];
}

export interface CreatePracticalTemplateItemDto {
  title: string;
  header: string;
  isActive: boolean;
  isMachineRelated: boolean;
  eventTypes: string[];
  professionId: string;
  trainingLevelId: string;
  mrPracticalTemplateDetails: CreateMrPracticalTemplateDetailsDto;
  nmrPracticalTemplateDetails: CreateNmrPracticalTemplateDetailsDto;
}

export class CreatePracticalTemplateValidator extends AbstractValidator<CreatePracticalTemplateItemDto> {
  constructor() {
    super();

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.title)
      .isNotEmpty()
      .fulfills(x => !isNullOrWhiteSpaces(x))
      .when(x => x != null)
      .withFailureMessage(i18n.t('Template Title is required'));

    this.validateIfString(x => x.professionId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIfIterable(x => x.eventTypes)
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Event Types are required'));

    this.validateIfEachString(x => x.eventTypes)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && (x.eventTypes || []).length !== 0)
      .withFailureMessage(i18n.t('At least one Event Type is wrong'));

    this.validateIfString(x => x.trainingLevelId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIf(x => x.mrPracticalTemplateDetails)
      .isDefined()
      .isNotNull()
      .when(x => x != null && x.isMachineRelated)
      .withFailureMessage(i18n.t('Machinery info is required'));

    this.validateIf(x => x.nmrPracticalTemplateDetails)
      .isDefined()
      .isNotNull()
      .when(x => x != null && !x.isMachineRelated)
      .withFailureMessage(i18n.t('Non Machine Related info is wrong'));

    this.validateIfString(x => x.mrPracticalTemplateDetails.clusterId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.isMachineRelated && x.mrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.mrPracticalTemplateDetails.equipmentTypeId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.isMachineRelated && x.mrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(x => x.mrPracticalTemplateDetails.oemId)
      .isUuid('4')
      .when(x => x != null && x.isMachineRelated && x.mrPracticalTemplateDetails != null && x.mrPracticalTemplateDetails.oemId != null)
      .withFailureMessage(i18n.t('OEM info is wrong'));

    // this.validateIfString(x => x.mrPracticalTemplateDetails.machineModelId)
    //   .isUuid('4')
    //   .when(
    //     x => x != null && x.isMachineRelated && x.mrPracticalTemplateDetails != null && x.mrPracticalTemplateDetails.machineModelId != null
    //   )
    //   .withFailureMessage(i18n.t('Machine Model info is wrong'));

    this.validateIfIterable(x => x.mrPracticalTemplateDetails.mrPracticalQuestions)
      .isNotEmpty()
      .when(x => x != null && x.isMachineRelated && x.mrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Questions are required'));

    this.validateIfEachString(x => x.mrPracticalTemplateDetails.mrPracticalQuestions)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x =>
          x != null &&
          x.isMachineRelated &&
          x.mrPracticalTemplateDetails != null &&
          (x.mrPracticalTemplateDetails.mrPracticalQuestions || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one question is wrong'));

    //NMR
    this.validateIfString(x => x.nmrPracticalTemplateDetails.nmrClusterId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && !x.isMachineRelated && x.nmrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.nmrPracticalTemplateDetails.functionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && !x.isMachineRelated && x.nmrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIfString(x => x.nmrPracticalTemplateDetails.trainingNameId)
      .isUuid('4')
      .when(
        x =>
          x != null && !x.isMachineRelated && x.nmrPracticalTemplateDetails != null && x.nmrPracticalTemplateDetails.trainingNameId != null
      )
      .withFailureMessage(i18n.t('Training Name info is wrong'));

    this.validateIfString(x => x.nmrPracticalTemplateDetails.functionalSubareaId)
      .isUuid('4')
      .when(
        x =>
          x != null &&
          !x.isMachineRelated &&
          x.nmrPracticalTemplateDetails != null &&
          x.nmrPracticalTemplateDetails.functionalSubareaId != null
      )
      .withFailureMessage(i18n.t('Functional Subarea info is wrong'));

    this.validateIfIterable(x => x.nmrPracticalTemplateDetails.nmrPracticalQuestions)
      .isNotEmpty()
      .when(x => x != null && !x.isMachineRelated && x.nmrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Questions are required'));

    this.validateIfEachString(x => x.nmrPracticalTemplateDetails.nmrPracticalQuestions)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x =>
          x != null &&
          !x.isMachineRelated &&
          x.nmrPracticalTemplateDetails != null &&
          (x.nmrPracticalTemplateDetails.nmrPracticalQuestions || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one question is wrong'));
  }
}

@repository('@@PRACTICALTEMPLATES', 'practical-templates.new')
export class NewPracticalTemplatesStore extends FormStore<CreatePracticalTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-practical-template';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreatePracticalTemplateItemDto) {
    return new CreatePracticalTemplateValidator().validate(item);
  }

  constructor() {
    super('NEW_PRACTICALTEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

export interface ChangePracticalTemplateItemDto {
  id: string;
  title: string;
  header: string;
  isActive: boolean;
  eventTypes: string[];
  trainingLevelId: string;
  mrPracticalTemplateDetails: ChangeMrPracticalTemplateDetailsDto;
  nmrPracticalTemplateDetails: ChangeNmrPracticalTemplateDetailsDto;
}

export interface ChangeMrPracticalTemplateDetailsDto {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelsId: string[];
  mrPracticalQuestions: string[];
}
export interface ChangeNmrPracticalTemplateDetailsDto {
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string;
  functionalSubareaId: string;
  nmrPracticalQuestions: string[];
}

export class ChangePracticalTemplateValidator extends AbstractValidator<ChangePracticalTemplateItemDto> {
  constructor() {
    super();

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Invalid data provided'));

    this.validateIfString(x => x.title)
      .isNotEmpty()
      .fulfills(x => !isNullOrWhiteSpaces(x))
      .when(x => x != null)
      .withFailureMessage(i18n.t('Template Title is required'));

    this.validateIfIterable(x => x.eventTypes)
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Event Types are required'));

    this.validateIfEachString(x => x.eventTypes)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && (x.eventTypes || []).length !== 0)
      .withFailureMessage(i18n.t('At least one Event Type is wrong'));

    this.validateIfString(x => x.trainingLevelId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIf(x => x)
      .fulfills(x => +(x.mrPracticalTemplateDetails != null) + +(x.nmrPracticalTemplateDetails != null) === 1)
      .when(x => x != null)
      .withFailureMessage(i18n.t('Machinery info is required'));

    this.validateIfString(x => x.mrPracticalTemplateDetails.clusterId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.mrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.mrPracticalTemplateDetails.equipmentTypeId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.mrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(x => x.mrPracticalTemplateDetails.oemId)
      .isUuid('4')
      .when(x => x != null && x.mrPracticalTemplateDetails != null && x.mrPracticalTemplateDetails.oemId != null)
      .withFailureMessage(i18n.t('OEM info is wrong'));

    // this.validateIfString(x => x.mrPracticalTemplateDetails.machineModelId)
    //   .isUuid('4')
    //   .when(x => x != null && x.mrPracticalTemplateDetails != null && x.mrPracticalTemplateDetails.machineModelId != null)
    //   .withFailureMessage(i18n.t('Machine Model info is wrong'));

    this.validateIfIterable(x => x.mrPracticalTemplateDetails.mrPracticalQuestions)
      .isNotEmpty()
      .when(x => x != null && x.mrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Questions are required'));

    this.validateIfEachString(x => x.mrPracticalTemplateDetails.mrPracticalQuestions)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x => x != null && x.mrPracticalTemplateDetails != null && (x.mrPracticalTemplateDetails.mrPracticalQuestions || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one question is wrong'));

    //NMR
    this.validateIfString(x => x.nmrPracticalTemplateDetails.nmrClusterId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.nmrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.nmrPracticalTemplateDetails.functionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.nmrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIfString(x => x.nmrPracticalTemplateDetails.trainingNameId)
      .isUuid('4')
      .when(x => x != null && x.nmrPracticalTemplateDetails != null && x.nmrPracticalTemplateDetails.trainingNameId != null)
      .withFailureMessage(i18n.t('Training Name info is wrong'));

    this.validateIfString(x => x.nmrPracticalTemplateDetails.functionalSubareaId)
      .isUuid('4')
      .when(x => x != null && x.nmrPracticalTemplateDetails != null && x.nmrPracticalTemplateDetails.functionalSubareaId != null)
      .withFailureMessage(i18n.t('Functional Subarea info is wrong'));

    this.validateIfIterable(x => x.nmrPracticalTemplateDetails.nmrPracticalQuestions)
      .isNotEmpty()
      .when(x => x != null && x.nmrPracticalTemplateDetails != null)
      .withFailureMessage(i18n.t('Questions are required'));

    this.validateIfEachString(x => x.nmrPracticalTemplateDetails.nmrPracticalQuestions)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x => x != null && x.nmrPracticalTemplateDetails != null && (x.nmrPracticalTemplateDetails.nmrPracticalQuestions || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one question is wrong'));
  }
}

@repository('@@PRACTICALTEMPLATES', 'practical-templates.change')
export class ChangePracticalTemplatesStore extends FormStore<ChangePracticalTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = '';
  updatePath = 'update-practical-template';

  protected validate(item: ChangePracticalTemplateItemDto) {
    return new ChangePracticalTemplateValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PRACTICALTEMPLATE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
