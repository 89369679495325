import { BaseDto, CommandResult, ImageInfo } from '../../types';
import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { Categories } from 'stores/requests/requests-store';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { ProfessionDto } from 'stores/configuration/profiles/professions-store';
import { TestCategoryDto } from '../testCategory-store';
import { ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { EquipmentTypeDto } from 'stores/configuration/machinery/equipment-types-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import { MachineUnitDto } from 'stores/configuration/machinery/machine-units-store';
import { PlcTypeDto } from 'stores/configuration/machinery/plc-types-store';
import { NMFunctionalSubareaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { NMTrainingNameDto } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { NMFunctionalAreaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { OemDto } from 'stores/configuration/machinery/oems-store';
import { FormStore } from 'stores/formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';

export type AnswerType = 'Unknown' | 'YesOrNo' | 'Rating';
export interface TheoreticalQuestionMRItemDto {
  id: string;
  friendlyId: string;
  question: TheoreticalQuestionDto;
  questionTranslations: TheoreticalQuestionDto[];
  professions: ProfessionDto[];
  testCategoryId: string;
  testCategory: TestCategoryDto;
  content: string; // URL
  mediaContentType: MediaContent;
  imageInfo: ImageInfo;
  clusterId: string;
  cluster: ClusterDto;
  equipmentTypeId: string;
  equipmentType: EquipmentTypeDto;
  oemId: string;
  oem: OemDto;
  machineModelId: string | null;
  machineModel: MachineModelDto;
  machineUnitId: string | null;
  machineUnit: MachineUnitDto;
  plcTypeId: string | null;
  plcType: PlcTypeDto;
  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}

export interface CreateTheoreticalQuestionMRItemDto {
  question: CreateTheoreticalQuestionDto;
  questionTranslations: CreateTheoreticalQuestionDto[];
  professions: { id: string }[];
  testCategoryId: string;
  content: string; // URL
  mediaContentType: MediaContent;
  clusterId: string;
  equipmentTypeId: string;
  oemId: string | null;
  machineModelId: string | null;
  machineUnitId: string | null;
  plcTypeId: string | null;
  trainingLevelId: string;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}

export interface CreateTheoreticalQuestionDto {
  text: string;
  languageId: string;
  optionA: string;
  optionB: string;
  optionC: string;
  optionD: string;
  optionE: string;
}

export interface ChangeTheoreticalQuestionMRItemDto {
  id: string;
  question: ChangeTheoreticalQuestionDto;
  questionTranslations: ChangeTheoreticalQuestionDto[];
  professions: { id: string }[];
  testCategoryId: string;
  content: string;
  mediaContentType: MediaContent;
  clusterId: string;
  equipmentTypeId: string;
  oemId: string | null;
  machineModelId: string | null;
  machineUnitId: string | null;
  plcTypeId: string | null;
  trainingLevelId: string;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}
export interface CreateNmrTheoreticalQuestionItemDto {
  question: ChangeTheoreticalQuestionDto;
  questionTranslations: ChangeTheoreticalQuestionDto[];
  professions: { id: string }[];
  testCategoryId: string;
  content: string;
  mediaContentType: MediaContent;
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string | null;
  functionalSubareaId: string | null;
  trainingLevelId: string;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}
export interface ChangeNmrTheoreticalQuestionItemDto {
  id: string;
  question: ChangeTheoreticalQuestionDto;
  questionTranslations: ChangeTheoreticalQuestionDto[];
  professions: { id: string }[];
  testCategoryId: string;
  content: string;
  mediaContentType: MediaContent;
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string | null;
  functionalSubareaId: string | null;
  trainingLevelId: string;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}
export interface ChangeTheoreticalQuestionDto {
  text: string;
  languageId: string;
  optionA: string;
  optionB: string;
  optionC: string;
  optionD: string;
  optionE: string;
}
export interface NmrTheoreticalQuestionItemDto {
  id: string;
  friendlyId: string;
  question: TheoreticalQuestionDto;
  questionTranslations: TheoreticalQuestionDto[];
  professions: ProfessionDto[];
  testCategoryId: string;
  testCategory: TestCategoryDto;
  content: string;
  mediaContentType: MediaContent;
  imageInfo: ImageInfo;
  nmrClusterId: string;
  nmrCluster: NMClusterDto;
  functionalAreaId: string;
  functionalArea: NMFunctionalAreaDto;
  trainingNameId: string | null;
  trainingName: NMTrainingNameDto;
  functionalSubareaId: string | null;
  functionalSubArea: NMFunctionalSubareaDto;
  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}
export interface CreateNmrTheoreticalQuestionItemDto {
  question: CreateTheoreticalQuestionDto;
  questionTranslations: CreateTheoreticalQuestionDto[];
  professions: { id: string }[];
  testCategoryId: string;
  content: string;
  mediaContentType: MediaContent;
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string | null;
  functionalSubareaId: string | null;
  trainingLevelId: string;
  answerType: AnswerType;
  isMandatory: boolean;
  option: OptionSelected;
}

export interface TheoreticalQuestionDto {
  text: string;
  language: LanguageDto;
  optionA: string;
  optionB: string;
  optionC: string;
  optionD: string;
  optionE: string;
}

export type OptionSelected = 'Unknown' | 'A' | 'B' | 'C' | 'D' | 'E' | 'Yes' | 'No';
// as enum => Unknown = 0,  A = 10,  B = 20,  C = 30,  D = 40,  E = 50,  Yes = 60,  No = 70

export type MediaContent = 'Image' | 'Video' | 'None';
// as enum => Image = 10,  Video = 20, Unknown = 0
export interface NMRTheoreticalTestQBDto extends BaseDto {
  friendlyId: string;
  question: { id: string; text: string };
  professions: { id: string; name: string }[];
  category: Categories;
  relation: {
    clusterName: string;
    functionalAreaName: string;
    trainingName: string;
  };
  contentType: 'Photo' | 'Video';
  answerType: AnswerType;
  trainingLevel: TrainingLevelDto;
  languages: LanguageDto[];
}

@repository('@@MR_THEORETICAL_TEST_QB', 'mrTheoreticalTestQB.summary')
export class MRTheoreticalQuestionBanksStore extends DataStore<TheoreticalQuestionMRItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-questions-mr';
  retrievePath = 'get-theoretical-questions-mr';
  updatePath = 'update-theoretical-question-mr';
  deletePath = 'delete-theoretical-question-mr';
  hardDeletePath = 'hard-delete-theoretical-question-mr';
  retrieveOnePath = 'get-theoretical-question-mr';
  retrieveCountPath = 'get-used-theoretical-question-mr-count';

  protected validate(item: TheoreticalQuestionMRItemDto) {
    return new ValidationResult();
  }

  public async getUsedMRTheoreticalQuestionCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('MR_THEORETICAL_TEST_QB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

export class MRTheoreticalQuestionValidator extends ExtendedAbstractValidator<NmrTheoreticalQuestionItemDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x);
  }
}
@repository('@@MR_THEORETICAL_TEST_QB', 'mrTheoreticalTestQB.wizard')
export class MRTheoreticalTestQBWizardStore extends FormStore<TheoreticalQuestionMRItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-questions-mr';
  retrievePath = 'get-theoretical-questions-mr';
  updatePath = 'update-theoretical-question-mr';
  deletePath = 'delete-theoretical-question-mr';
  hardDeletePath = 'hard-delete-theoretical-question-mr';
  retrieveOnePath = 'get-theoretical-question-mr';
  retrieveCountPath = 'get-used-theoretical-question-mr-count';

  protected validate(item: TheoreticalQuestionMRItemDto) {
    return new MRTheoreticalQuestionValidator().extendValidate(item as any);
  }

  constructor() {
    super('MR_THEORETICAL_TEST_QB_WIZARD', {
      isBusy: false,
      item: undefined,
      count: 0,
      status: 'New',
      result: undefined,
      discard: item => {}
    });
  }

  public submitQuestion = async (update: boolean = false) => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    if (update) {
      const changeTheoreticalQuestionMRItemDto = toChangeTheoreticalQuestionMRItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.put<ChangeTheoreticalQuestionMRItemDto, CommandResult<ChangeTheoreticalQuestionMRItemDto>>(
          `${this.baseUrl}/${this.updatePath}`,
          changeTheoreticalQuestionMRItemDto
        )
      );
      return result.data;
    } else {
      const createTheoreticalQuestionMRItemDto = toCreateTheoreticalQuestionMRItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<CreateTheoreticalQuestionMRItemDto, CommandResult<CreateTheoreticalQuestionMRItemDto>>(
          `${this.baseUrl}/${this.createPath}`,
          createTheoreticalQuestionMRItemDto
        )
      );
      return result.data;
    }
  };
}

const toChangeTheoreticalQuestionMRItemDto = (item: TheoreticalQuestionMRItemDto): ChangeTheoreticalQuestionMRItemDto => {
  if (!item) return;
  const {
    id,
    question,
    questionTranslations,
    professions,
    testCategoryId,
    content,
    mediaContentType,
    clusterId,
    equipmentTypeId,
    oemId,
    machineModelId,
    machineUnitId,
    plcTypeId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  } = item;
  const { language, optionA, optionB, optionC, optionD, optionE, text } = question;
  return {
    id,
    question: { optionA, optionE, optionD, optionC, optionB, text, languageId: language.id },
    questionTranslations: (questionTranslations || []).map(({ language, optionA, optionB, optionC, optionD, optionE, text }) => ({
      optionA,
      optionE,
      optionD,
      optionC,
      optionB,
      text,
      languageId: language.id
    })),
    professions: (professions || []).map(({ id }) => ({ id })),
    testCategoryId,
    content,
    mediaContentType: content ? mediaContentType : 'None',
    clusterId,
    equipmentTypeId,
    oemId: !clusterId || !equipmentTypeId ? null : oemId,
    machineModelId: !clusterId || !equipmentTypeId || !oemId ? null : machineModelId,
    machineUnitId: !equipmentTypeId || !oemId ? null : machineUnitId,
    plcTypeId: !equipmentTypeId || !oemId ? null : plcTypeId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  };
};
const toCreateTheoreticalQuestionMRItemDto = (item: TheoreticalQuestionMRItemDto): CreateTheoreticalQuestionMRItemDto => {
  if (!item) return;
  const {
    question,
    questionTranslations,
    professions,
    testCategoryId,
    content,
    mediaContentType,
    clusterId,
    equipmentTypeId,
    oemId,
    machineModelId,
    machineUnitId,
    plcTypeId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  } = item;
  const { language, optionA, optionB, optionC, optionD, optionE, text } = question;
  return {
    question: { optionA, optionE, optionD, optionC, optionB, text, languageId: language.id },
    questionTranslations: (questionTranslations || []).map(({ language, optionA, optionB, optionC, optionD, optionE, text }) => ({
      optionA,
      optionE,
      optionD,
      optionC,
      optionB,
      text,
      languageId: language.id
    })),
    professions: (professions || []).map(({ id }) => ({ id })),
    testCategoryId,
    content,
    mediaContentType: content ? mediaContentType : 'None',
    clusterId,
    equipmentTypeId,
    oemId: !clusterId || !equipmentTypeId ? null : oemId,
    machineModelId: !clusterId || !equipmentTypeId || !oemId ? null : machineModelId,
    machineUnitId: !equipmentTypeId || !oemId ? null : machineUnitId,
    plcTypeId: !equipmentTypeId || !oemId ? null : plcTypeId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  };
};

export class NmrTheoreticalQuestionValidator extends ExtendedAbstractValidator<NmrTheoreticalQuestionItemDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x);
  }
}

@repository('@@NMR_THEORETICAL_TEST_QB', 'nmrTheoreticalTestQB.summary')
export class NMRTheoreticalQuestionBanksStore extends DataStore<NmrTheoreticalQuestionItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-nmr-theoretical-question';
  retrievePath = 'get-nmr-theoretical-questions';
  updatePath = 'update-nmr-theoretical-question';
  deletePath = 'delete-nmr-theoretical-question';
  hardDeletePath = 'hard-delete-nmr-theoretical-question';
  retrieveOnePath = 'get-nmr-theoretical-question';
  retrieveCountPath = 'get-used-theoretical-question-nmr-count';

  protected validate(item: NmrTheoreticalQuestionItemDto) {
    return new ValidationResult();
  }

  public async getUsedNMRTheoreticalQuestionCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('NMR_THEORETICAL_TEST_QB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
@repository('@@NMR_THEORETICAL_TEST_QB', 'nmrTheoreticalTestQB.wizard')
export class NMRTheoreticalQBWizardStore extends FormStore<NmrTheoreticalQuestionItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-nmr-theoretical-question';
  retrievePath = 'get-nmr-theoretical-questions';
  updatePath = 'update-nmr-theoretical-question';
  deletePath = 'delete-nmr-theoretical-question';
  hardDeletePath = 'hard-delete-nmr-theoretical-question';
  retrieveOnePath = 'get-nmr-theoretical-question';
  retrieveCountPath = 'get-nmr-used-theoretical-question-count';

  protected validate(item: NmrTheoreticalQuestionItemDto) {
    return new NmrTheoreticalQuestionValidator().extendValidate(item as any);
  }

  constructor() {
    super('NMR_THEORETICAL_TEST_QB_WIZARD', {
      isBusy: false,
      item: undefined,
      status: 'New',
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public submitQuestion = async (update: boolean = false) => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    if (update) {
      const changeNmrTheoreticalQuestionItemDto = toChangeTheoreticalQuestionNMRItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.put<ChangeNmrTheoreticalQuestionItemDto, CommandResult<ChangeNmrTheoreticalQuestionItemDto>>(
          `${this.baseUrl}/${this.updatePath}`,
          changeNmrTheoreticalQuestionItemDto
        )
      );
      return result.data;
    } else {
      const createNmrTheoreticalQuestionItemDto = toCreateTheoreticalQuestionNMRItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<CreateNmrTheoreticalQuestionItemDto, CommandResult<CreateNmrTheoreticalQuestionItemDto>>(
          `${this.baseUrl}/${this.createPath}`,
          createNmrTheoreticalQuestionItemDto
        )
      );
      return result.data;
    }
  };
}

const toChangeTheoreticalQuestionNMRItemDto = (item: NmrTheoreticalQuestionItemDto): ChangeNmrTheoreticalQuestionItemDto => {
  if (!item) return;
  const {
    id,
    question,
    questionTranslations,
    professions,
    testCategoryId,
    content,
    mediaContentType,
    nmrClusterId,
    functionalAreaId,
    trainingNameId,
    functionalSubareaId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  } = item;
  const { language, optionA, optionB, optionC, optionD, optionE, text } = question;
  return {
    id,
    question: { optionA, optionE, optionD, optionC, optionB, text, languageId: language.id },
    questionTranslations: (questionTranslations || []).map(({ language, optionA, optionB, optionC, optionD, optionE, text }) => ({
      optionA,
      optionE,
      optionD,
      optionC,
      optionB,
      text,
      languageId: language.id
    })),
    professions: (professions || []).map(({ id }) => ({ id })),
    testCategoryId,
    content,
    mediaContentType: content ? mediaContentType : 'None',
    nmrClusterId,
    functionalAreaId,
    trainingNameId: !nmrClusterId || !functionalAreaId ? null : trainingNameId,
    functionalSubareaId: !nmrClusterId || !functionalAreaId || !trainingNameId ? null : functionalSubareaId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  };
};
const toCreateTheoreticalQuestionNMRItemDto = (item: NmrTheoreticalQuestionItemDto): CreateNmrTheoreticalQuestionItemDto => {
  if (!item) return;
  const {
    question,
    questionTranslations,
    professions,
    testCategoryId,
    content,
    mediaContentType,
    nmrClusterId,
    functionalAreaId,
    trainingNameId,
    functionalSubareaId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  } = item;
  const { language, optionA, optionB, optionC, optionD, optionE, text } = question;
  return {
    question: { optionA, optionE, optionD, optionC, optionB, text, languageId: language.id },
    questionTranslations: (questionTranslations || []).map(({ language, optionA, optionB, optionC, optionD, optionE, text }) => ({
      optionA,
      optionE,
      optionD,
      optionC,
      optionB,
      text,
      languageId: language.id
    })),
    professions: (professions || []).map(({ id }) => ({ id })),
    testCategoryId,
    content,
    mediaContentType: content ? mediaContentType : 'None',
    nmrClusterId,
    functionalAreaId,
    trainingNameId: !nmrClusterId || !functionalAreaId ? null : trainingNameId,
    functionalSubareaId: !nmrClusterId || !functionalAreaId || !trainingNameId ? null : functionalSubareaId,
    trainingLevelId,
    answerType,
    isMandatory,
    option
  };
};
