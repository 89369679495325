import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, Icon, Button, Label, Form } from 'semantic-ui-react';
import PeriodInput from 'widgets/form/period-input';
import { DateTimePeriod, NewEventSupportPositionsDto, EventPositionDto } from 'stores/events/events-store';
import { UserDto } from 'stores/users/users-store';
import ChooseStudentsForm from 'widgets/form/choose-students-form';
import { DatePeriod } from 'site/pages/landing-pages/shared-scheduler-components/events/scheduler-period-service';
import { ReducerType, useStateReducer } from 'utils/react-custom-hooks';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import { DurationInput } from 'widgets/form/durationInput';
import { MoneyInput } from 'widgets/form/moneyInput';
import EventNewModelPositionComponent from './new-event-model-position-component';
import './new-events-support-details.style.less';
import moment from 'moment';
import { Fragment, useEffect } from 'react';
import PatternMachines, { PatternMachinesViewModel } from 'site/pages/shared/events-and-requests/pattern-machines';
import { ItemReference } from 'stores/dataStore';

interface NewInfoSupportDetailedProps extends WithTranslation, NewEventSupportPositionsDto {
  readonly: boolean;
  onChange: (values: NewEventSupportPositionsDto) => void;
  onNewPosition: (newUser: UserDto, dates: DatePeriod) => void;
  isHideEditButton: boolean;
}

interface NewInfoSupportDetailedState {
  showEmployeeSelectionModal: boolean;
  newPosition: boolean;
  userChangeIndex: number;
  arePlannedDaysCompleted: boolean;
  warningText: string;
}

const getDefaultValues = (): NewInfoSupportDetailedState => ({
  showEmployeeSelectionModal: false,
  newPosition: false,
  userChangeIndex: -1,
  arePlannedDaysCompleted: true,
  warningText: ''
});

const NewInfoSupportDetailed: React.FC<NewInfoSupportDetailedProps> = props => {
  const {
    supportPositionRoleId,
    patternId,
    patternName,
    machineModels,
    requestedManDays,
    requestedWorkingManHours,
    theoreticalCost,
    requestEndDate,
    requestStartDate,
    eventNewModelPositions,
    t,
    onChange,
    onNewPosition,
    ...rest
  } = props;

  const { positionsRequested, id, readonly, isHideEditButton } = rest;

  const [{ showEmployeeSelectionModal, newPosition, userChangeIndex, arePlannedDaysCompleted, warningText }, setState] = React.useReducer<
    ReducerType<NewInfoSupportDetailedState>,
    NewInfoSupportDetailedState
  >(useStateReducer, getDefaultValues(), getDefaultValues);

  const initialItem = {
    id,
    supportPositionRoleId,
    patternId,
    patternName,
    machineModels,
    requestedManDays,
    requestedWorkingManHours,
    theoreticalCost,
    positionsRequested,
    eventNewModelPositions,
    requestEndDate,
    requestStartDate
  };

  const myPattern = patternId ? ({ id: patternId, title: patternName ?? '' } as ItemReference) : null;

  const myMachines = (machineModels || []).map(m => ({
    cluster: m.machineRelatedClusterId ? { id: m.machineRelatedClusterId, title: m.machineRelatedClusterName } : null,
    equipmentType: m.equipmentTypeId ? { id: m.equipmentTypeId, title: m.equipmentTypeName } : null,
    oem: m.oemId ? { id: m.oemId, title: m.oemName } : null,
    machineModel: m.machineModelId ? { id: m.machineModelId, title: m.machineModelName } : null,
    machineUnits: m.machineUnitRequestMachines,
    plcTypes: m.plcTypeRequestMachines
  }));

  const patternMachines: PatternMachinesViewModel = { pattern: myPattern, machines: myMachines };

  const openModal = (isNewPosition: boolean = false) => {
    const changes: Partial<NewInfoSupportDetailedState> = { showEmployeeSelectionModal: true };
    if (isNewPosition) changes.newPosition = isNewPosition;
    setState(changes);
  };

  useEffect(() => {
    const currentlyRequestedDates = eventNewModelPositions.reduce((prevVal, acc) => prevVal + Number(acc['plannedDays']), 0);
    const completed = requestedManDays === currentlyRequestedDates;
    setState({
      arePlannedDaysCompleted: completed,
      warningText: completed ? '' : `You have Requested ${requestedManDays} man Day(s) and have planned ${currentlyRequestedDates} Day(s)`
    });
  }, [eventNewModelPositions, requestedManDays]);

  const closeModal = () => setState({ showEmployeeSelectionModal: false });

  const onEmployeesSelected = (employees: UserDto[]) => {
    const employee = employees.firstOrDefault();
    if (employee == null) return;
    if (newPosition) onNewPosition(employee, { from: requestStartDate, to: requestEndDate });
    else {
      const eventNewModelPositions = props.eventNewModelPositions.map((eventNewModelPosition, i) =>
        userChangeIndex === i
          ? {
              ...eventNewModelPosition,
              userLocationId: employee.roles.find(({ role }) => role.name === 'Employee').location.id,
              userId: employee.id,
              user: employee
            }
          : eventNewModelPosition
      );
      onChange({ ...initialItem, eventNewModelPositions });
    }

    setState({ newPosition: false, userChangeIndex: -1 });
  };

  const changeEmployeeHandler = (idx: number) => setState({ userChangeIndex: idx, showEmployeeSelectionModal: true });

  const removeEventNewModelPositionHandler = (index: number) => {
    const eventNewModelPositions = props.eventNewModelPositions.filter((_, i) => i !== index);
    onChange({ ...initialItem, eventNewModelPositions });
  };

  const formatInput = (e: any) => {
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const datesChangeHandler = ({ from, to }: DateTimePeriod, idx: number, travelDays: boolean = false) => {
    let eventNewModelPositions: EventPositionDto[];
    const fromDate = travelDays ? 'travelDateFrom' : 'startDate';
    const toDate = travelDays ? 'travelDateTo' : 'endDate';
    if (travelDays) {
      if (from !== props.eventNewModelPositions[idx][fromDate])
        eventNewModelPositions = props.eventNewModelPositions.map((newSupportPos, i) =>
          i === idx ? { ...newSupportPos, [fromDate]: from } : newSupportPos
        );
      else if (to !== props.eventNewModelPositions[idx][toDate])
        eventNewModelPositions = props.eventNewModelPositions.map((newSupportPos, i) =>
          i === idx ? { ...newSupportPos, [toDate]: to } : newSupportPos
        );
    } else {
      if (from !== props.eventNewModelPositions[idx][fromDate])
        eventNewModelPositions = props.eventNewModelPositions.map((newSupportPos, i) =>
          i === idx
            ? {
                ...newSupportPos,
                [fromDate]: from,
                plannedDays: moment(from).isSameOrBefore(moment(props.eventNewModelPositions[idx][toDate]))
                  ? moment(props.eventNewModelPositions[idx][toDate]).diff(moment(from), 'days') + 1
                  : 0
              }
            : newSupportPos
        );
      else if (to !== props.eventNewModelPositions[idx][toDate])
        eventNewModelPositions = props.eventNewModelPositions.map((newSupportPos, i) =>
          i === idx
            ? {
                ...newSupportPos,
                [toDate]: to,
                plannedDays: moment(to).isSameOrAfter(moment(props.eventNewModelPositions[idx][fromDate]))
                  ? moment(to).diff(moment(props.eventNewModelPositions[idx][fromDate]), 'days') + 1
                  : 0
              }
            : newSupportPos
        );
    }

    eventNewModelPositions && onChange({ ...initialItem, eventNewModelPositions });
  };

  const inputChangeHandler = (property: string, value: any, idx: number) => {
    let eventNewModelPositions;
    eventNewModelPositions = props.eventNewModelPositions.map((supportPos, i) =>
      i === idx ? { ...supportPos, [property]: value } : supportPos
    );
    eventNewModelPositions && onChange({ ...initialItem, eventNewModelPositions });
  };

  const handleOnPatternMachinesChange = ({ pattern, machines }: PatternMachinesViewModel) => {
    let patternId;
    let patternName;
    let machineModels;

    patternId = pattern?.id ?? null;
    patternName = pattern?.title ?? null;
    machineModels = (machines || []).map(({ cluster, equipmentType, machineModel, machineUnits, oem, plcTypes }) => ({
      machineRelatedClusterId: cluster?.id,
      machineRelatedClusterName: cluster?.title,
      equipmentTypeId: equipmentType?.id,
      equipmentTypeName: equipmentType?.title,
      oemId: oem?.id,
      oemName: oem?.title,
      machineModelId: machineModel?.id,
      machineModelName: machineModel?.title,
      plcTypeRequestMachines: plcTypes,
      machineUnitRequestMachines: machineUnits
    }));

    onChange({ ...initialItem, patternId, patternName, machineModels });
  };

  return (
    <div>
      <div>
        <div className="events-support-details__main-detail">
          <div className="main-separator">
            <div className="events-support-details__info-position">
              <div className="supppor-details__item-main-segment">
                <label className="support-details__segment-label">{t('Dates')}</label>
                <PeriodInput readOnly={true} onChange={period => {}} icon={false} value={{ from: requestStartDate, to: requestEndDate }} />
              </div>
              {/* </div> */}

              <div className="vis-borde-01">
                <Form key={'Form'} className="wizard__step2__machine-related">
                  <Form.Group className="wizard__step2__machine-related__content">
                    <div className="form__event-details-tab__mr__pattern form__event-details-tab__element wizard__mr-table mr-table-component__common-styles">
                      <PatternMachines
                        readOnly={true}
                        value={patternMachines}
                        onChange={handleOnPatternMachinesChange}
                        loadPatternsOnOpen={true}
                      />
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="supppor-details__item-main-segment">
                <label className="support-details__segment-label">{t('Role')}</label>
                <ProfessionEditor
                  readOnly
                  placeholder={t('Role')}
                  value={supportPositionRoleId ? { id: supportPositionRoleId, title: '' } : null}
                  onChange={role => {}}
                />
              </div>
              <div className="supppor-details__item-main-segment">
                <label className="support-details__segment-label">Requested HC</label>
                <Input disabled type="number" value={positionsRequested} onChange={(_, { value }) => {}} />
              </div>
              <div className="supppor-details__item-main-segment">
                <label className="support-details__segment-label">{t('Requested Man Days')}</label>
                <Input disabled={true} type="number">
                  <DurationInput onChange={value => {}} value={`${requestedManDays}`} />
                </Input>
              </div>
              <div className="supppor-details__item-main-segment">
                <label className="support-details__segment-label">{t('Requested Working Man hours')}</label>
                <Input disabled={true} type="number" onChange={value => {}} value={`${requestedWorkingManHours}`}></Input>
              </div>
              <div className="supppor-details__item-main-segment">
                <label className="support-details__segment-label">{t('Theoretical Cost')}</label>
                <Input disabled={true} type="number">
                  <MoneyInput onChange={value => {}} value={theoreticalCost} currencyChar="$" decimals={2} />
                </Input>
              </div>
              <div>
                <Button disabled={readonly} onClick={() => openModal(true)} icon>
                  Add Instructor <Icon name="user plus" />
                </Button>
              </div>
              <div>
                {!arePlannedDaysCompleted && (
                  <Label className="modal__action-warning wizard__action-warning">
                    <Icon name="exclamation triangle" />
                    <div>
                      <Fragment key={'date-warning-'}>{<p className="modal__action-warning__text"> {warningText} </p>}</Fragment>
                    </div>
                  </Label>
                )}
              </div>
            </div>
            {(machineModels || []).length > 0 && (
              <EventNewModelPositionComponent
                eventNewModelPositions={eventNewModelPositions || []}
                readonly={readonly}
                isHideEditButton={isHideEditButton}
                datesChangeHandler={datesChangeHandler}
                inputChangeHandler={inputChangeHandler}
                changeEmployeeHandler={changeEmployeeHandler}
                formatInput={formatInput}
                removeEventNewModelPositionHandler={removeEventNewModelPositionHandler}
                supportPositionId={id}
                machine={machineModels[0].machineModelId}
                pattern={patternId ?? ''}
              />
            )}
          </div>
          {showEmployeeSelectionModal && (
            <ChooseStudentsForm
              maxSelection={1}
              showSFPositionFields
              showPositionCodeFields
              onAddParticipants={onEmployeesSelected}
              onCloseUsersModal={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(React.memo(NewInfoSupportDetailed));
