import { repository } from 'redux-scaffolding-ts';
import { FormStore } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { ItemReference } from 'stores/dataStore';
import { EventStatus } from 'stores/events/events-store';
import { NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { ProfessionDto } from 'stores/configuration/profiles/professions-store';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { NMFunctionalAreaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { NMTrainingNameDto } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { NMFunctionalSubareaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { OemDto } from 'stores/configuration/machinery/oems-store';
import { EquipmentTypeDto } from 'stores/configuration/machinery/equipment-types-store';
import { ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { TestCategoryDto } from '../testCategory-store';
import { PlcTypeDto } from 'stores/configuration/machinery/plc-types-store';
import { MachineUnitDto } from 'stores/configuration/machinery/machine-units-store';
import {
  FeedbackFormQuestionAnswerDto,
  QuestionAnswerViewModel,
  FeedbackFormViewModel,
  ChangeFeedbackFormAnswersDto
} from '../forms/feedback-form.store';
import { ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import { ItemResult } from 'stores/types';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import {
  CreateTheoreticalTemplateItemDto,
  TheoreticalTemplateItemDto,
  RelatedToType,
  ChangeTheoreticalTemplateItemDto
} from '../templates/theoretical-templates-store';
import { TheoreticalQuestionMRItemDto } from '../questionBank/theoretical-test-store';

export class TheoreticalTemplateWizardViewModel {
  id: string;
  title: string;
  header: string;

  professionId: string;
  profileId: string;

  machineRelated?: boolean;
  relatedTo?: RelatedToType;
  status?: EventStatus;
  duration?: number;

  trainingLevel: ItemReference;
  mrCluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;

  nmrCluster: ItemReference;
  functionalArea: ItemReference;
  trainingName: ItemReference;
  functionalSubarea: ItemReference;

  questionsMr?: QuestionViewMrModel[];
  questionsNMr?: QuestionViewNmrModel[];

  isActive: boolean;
}

export interface QuestionViewMrModel {
  id: string;
  friendlyId: string;
  content: string;
  testCategory: TestCategoryDto;
  trainingLevel: TrainingLevelDto;
  machineUnit: MachineUnitDto;
  plcType: PlcTypeDto;
  cluster: ClusterDto;
  equipmentType?: EquipmentTypeDto;
  oem: OemDto;
  question: string;
  professions: ProfessionDto[];
}

export interface QuestionViewNmrModel {
  id: string;
  friendlyId: string;
  content: string;
  nmrCluster: NMClusterDto;
  functionalArea: NMFunctionalAreaDto;
  testCategory: TestCategoryDto;
  professions: ProfessionDto[];
  trainingName: NMTrainingNameDto;
  functionalSubArea: NMFunctionalSubareaDto;
  trainingLevel: TrainingLevelDto;
  question: string;
}

export const toMachineRelatedQuestionsViewModel = (items: TheoreticalQuestionMRItemDto[]): QuestionViewMrModel[] => {
  if ((items || []).length > 0) return [];
  return items.map(item => ({
    professions: item.professions,
    id: item.id,
    friendlyId: item.friendlyId,
    content: item.content,
    testCategory: item.testCategory,
    trainingLevel: item.trainingLevel,
    machineUnit: item.machineUnit,
    plcType: item.plcType,
    cluster: item.cluster,
    equipmentType: item.equipmentType,
    oem: item.oem,
    question: item.question.text
  }));
};

export class TheoreticalTemplateWizardViewModelValidator extends ExtendedAbstractValidator<TheoreticalTemplateWizardViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.title)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Template Title is required'));

    this.validateIf(x => x.machineRelated)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Machine related Yes/No date is required'));

    this.validateIf(x => x.relatedTo)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Related to Event is required'));

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .fulfills(
        ({ relatedTo, professionId, profileId }) =>
          (relatedTo?.toString() === 'Event' && professionId != null) || (relatedTo?.toString() === 'TNA' && profileId != null)
      )
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIf(x => x.status)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && x.relatedTo && x.relatedTo.toString() === 'Event')
      .withFailureMessage(i18n.t('Status is required'));

    this.validateIf(x => x.trainingLevel)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && x.relatedTo && x.relatedTo.toString() === 'Event')
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIfNumber(x => x.duration)
      .isDefined()
      .isGreaterThan(0)
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Set duration is required'));

    this.validateIf(x => x.relatedTo)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Related to Event or TNA is required'));

    this.validateIf(x => x.machineRelated && x.mrCluster.id)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIf(x => x.machineRelated && x.mrCluster.id && x.equipmentType.id)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ machineRelated, questionsMr, questionsNMr }) =>
          (machineRelated && questionsMr?.length > 0) || (!machineRelated && questionsNMr?.length > 0)
      )
      .withFailureMessage(i18n.t('At least one question is required'));
  }
}

@repository('@@THEORETICALTEMPLATES', 'theoretical-templates.wizard')
export class TheoreticalTemplateWizardStore extends FormStore<TheoreticalTemplateWizardViewModel> {
  baseUrl = 'events';
  createPath = 'v1/new-theoretical-template';
  retrievePath = '';
  updatePath = 'v1/update-theoretical-template';
  deletePath = '';
  retrieveOnePath = '';

  constructor() {
    super('THEORETICALTEMPLATES', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  protected validate(item: TheoreticalTemplateWizardViewModel) {
    return new TheoreticalTemplateWizardViewModelValidator().extendValidate(item as any);
  }

  public resetData(partialItem?: Partial<TheoreticalTemplateWizardViewModel>) {
    let newPartialItem = partialItem;

    // Reset Machinery
    if (newPartialItem.professionId || newPartialItem.profileId || newPartialItem.machineRelated) {
      this.toResetMachinery(newPartialItem);
    }

    // Reset Questions
    if (newPartialItem.professionId || newPartialItem.profileId || newPartialItem.machineRelated) {
      newPartialItem.questionsNMr = [];
      newPartialItem.questionsMr = [];
    }

    if (newPartialItem.machineRelated && (newPartialItem.mrCluster?.id || newPartialItem.equipmentType?.id)) {
      newPartialItem.questionsNMr = [];
      newPartialItem.questionsMr = [];
    }

    if (!newPartialItem.machineRelated && (newPartialItem.nmrCluster?.id || newPartialItem.functionalArea?.id)) {
      newPartialItem.questionsNMr = [];
      newPartialItem.questionsMr = [];
    }

    // Reset TrainingLevel
    if (newPartialItem.relatedTo) newPartialItem.trainingLevel = null;

    super.change(newPartialItem);
  }

  public toResetMachinery(newPartialItem) {
    newPartialItem.mrCluster = null;
    newPartialItem.oem = null;
    newPartialItem.equipmentType = null;
    newPartialItem.functionalArea = null;
    newPartialItem.functionalSubarea = null;
    newPartialItem.machineModel = null;
    newPartialItem.trainingName = null;
  }

  public async createTheoreticalTemplate() {
    const { item } = this.state;
    const createTheoreticalTemplateItemDto = this.toCreateTheoreticalTemplateFormDto(item);
    const eventFormViewModelValidation = this.validate(item);

    if (eventFormViewModelValidation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, eventFormViewModelValidation);
      return { isSuccess: false };
    }

    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreateTheoreticalTemplateItemDto, ItemResult<any>>(
        `${this.baseUrl}/${this.createPath}`,
        createTheoreticalTemplateItemDto
      )
    );

    return result.data;
  }
  private toCreateTheoreticalTemplateFormDto(viewModel: TheoreticalTemplateWizardViewModel): CreateTheoreticalTemplateItemDto {
    return {
      title: viewModel.title,
      header: viewModel.header,
      isActive: true,
      isMachineRelated: viewModel.machineRelated,
      professionItemId: viewModel.relatedTo.toString() === 'Event' ? viewModel.professionId : null, // in tna null,
      profileId: viewModel.profileId,
      typeRelated: viewModel.relatedTo,
      status: viewModel.status,
      hoursDuration: viewModel.duration,
      trainingLevelId: viewModel.trainingLevel?.id,
      theoreticalTemplateMRDetails: !!viewModel.machineRelated
        ? {
            clusterId: viewModel.mrCluster?.id,
            equipmentTypeId: viewModel.equipmentType?.id,
            oemId: viewModel.oem && viewModel.oem?.id,
            machineModelId: viewModel.machineModel && viewModel.machineModel?.id,
            theoreticalQuestionsMR: ((viewModel.questionsMr as QuestionViewMrModel[]) || []).map(x => x?.id)
          }
        : null,
      nmrTheoreticalTemplateDetails: !viewModel.machineRelated
        ? {
            nmrClusterId: viewModel.nmrCluster?.id,
            functionalAreaId: viewModel.functionalArea?.id,
            trainingNameId: viewModel.trainingName?.id,
            functionalSubareaId: viewModel.functionalSubarea?.id,
            nmrTheoreticalQuestions: ((viewModel.questionsNMr as QuestionViewNmrModel[]) || []).map(x => x?.id)
          }
        : null
    };
  }
}

export const toChangeTheoreticalTemplateFormDto = (viewModel: TheoreticalTemplateWizardViewModel): ChangeTheoreticalTemplateItemDto => {
  return {
    id: viewModel.id,
    title: viewModel.title,
    header: viewModel.header,
    isActive: viewModel.isActive,
    isMachineRelated: viewModel.machineRelated,
    professionItemId: viewModel.relatedTo.toString() === 'Event' ? viewModel.professionId : null,
    typeRelated: viewModel.relatedTo,
    status: viewModel.status,
    hoursDuration: viewModel.duration,
    trainingLevelId: viewModel.trainingLevel.id,
    theoreticalTemplateMRDetails: !!viewModel.machineRelated
      ? {
          clusterId: viewModel.mrCluster.id,
          equipmentTypeId: viewModel.equipmentType.id,
          oemId: viewModel.oem && viewModel.oem.id,
          machineModelId: viewModel.machineModel && viewModel.machineModel.id,
          theoreticalQuestionsMR: ((viewModel.questionsMr as QuestionViewMrModel[]) || []).map(x => x.id)
        }
      : null,
    nmrTheoreticalTemplateDetails: !viewModel.machineRelated
      ? {
          nmrClusterId: viewModel.nmrCluster.id,
          functionalAreaId: viewModel.functionalArea.id,
          trainingNameId: viewModel.trainingName && viewModel.trainingName.id,
          functionalSubareaId: viewModel.functionalSubarea && viewModel.functionalSubarea.id,
          nmrTheoreticalQuestions: ((viewModel.questionsNMr as QuestionViewNmrModel[]) || []).map(x => x.id)
        }
      : null
  };
};
export interface CreateTheoreticalTemplateNmrDetailsDto {
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string;
  functionalSubareaId: string;
  nmrTheoreticalQuestions: string[];
}

export function mapToTemplateDataModel(item: TheoreticalTemplateItemDto): TheoreticalTemplateWizardViewModel {
  if (!item) return null;

  const {
    isMachineRelated,
    typeRelated,
    profession,
    profileId,
    hoursDuration,
    trainingLevel,
    theoreticalTemplateMRDetails,
    friendlyId: _0,
    professionItemId: _2,
    trainingLevelId: _3,
    isActive,
    nmrTheoreticalTemplateDetails,
    ...rest
  } = item;

  return {
    ...rest,
    profileId: profileId ? profileId : null,
    machineRelated: isMachineRelated,
    relatedTo: typeRelated,
    duration: hoursDuration,
    isActive: isActive,
    trainingLevel: { id: trainingLevel?.id, title: trainingLevel?.name },
    mrCluster: theoreticalTemplateMRDetails
      ? { id: theoreticalTemplateMRDetails.cluster?.id, title: theoreticalTemplateMRDetails.cluster?.name }
      : null,
    equipmentType: theoreticalTemplateMRDetails
      ? { id: theoreticalTemplateMRDetails.equipmentType?.id, title: theoreticalTemplateMRDetails.equipmentType?.name }
      : null,
    oem:
      theoreticalTemplateMRDetails && theoreticalTemplateMRDetails.oem
        ? { id: theoreticalTemplateMRDetails.oem.id, title: theoreticalTemplateMRDetails.oem.name }
        : null,
    machineModel:
      theoreticalTemplateMRDetails && theoreticalTemplateMRDetails.machineModel
        ? { id: theoreticalTemplateMRDetails.machineModel.id, title: theoreticalTemplateMRDetails.machineModel.name }
        : null,

    nmrCluster:
      nmrTheoreticalTemplateDetails && nmrTheoreticalTemplateDetails.nmrCluster
        ? { id: nmrTheoreticalTemplateDetails.nmrCluster.id, title: nmrTheoreticalTemplateDetails.nmrCluster.name }
        : null,

    functionalArea:
      nmrTheoreticalTemplateDetails && nmrTheoreticalTemplateDetails.functionalArea
        ? { id: nmrTheoreticalTemplateDetails.functionalArea.id, title: nmrTheoreticalTemplateDetails.functionalArea.name }
        : null,

    trainingName:
      nmrTheoreticalTemplateDetails && nmrTheoreticalTemplateDetails.trainingName
        ? { id: nmrTheoreticalTemplateDetails.trainingName.id, title: nmrTheoreticalTemplateDetails.trainingName.name }
        : null,

    functionalSubarea: nmrTheoreticalTemplateDetails &&
      nmrTheoreticalTemplateDetails.functionalSubArea && {
        id: nmrTheoreticalTemplateDetails.functionalSubArea.id,
        title: nmrTheoreticalTemplateDetails.functionalSubArea.name
      },
    questionsMr: item.theoreticalTemplateMRDetails ? toGenerateMrQuestions(item.theoreticalTemplateMRDetails.theoreticalQuestionsMR) : null,
    questionsNMr: item.nmrTheoreticalTemplateDetails
      ? toGenerateNMrQuestions(item.nmrTheoreticalTemplateDetails.nmrTheoreticalQuestions)
      : null,

    professionId: profession ? profession.id : null
  };
}

export function toGenerateMrQuestions(items: TheoreticalQuestionMRItemDto[]): QuestionViewMrModel[] {
  const questionsList = (items || []).map(
    ({
      id,
      friendlyId,
      content,
      testCategory,
      trainingLevel,
      machineUnit,
      plcType,
      cluster,
      equipmentType,
      oem,
      question,
      professions
    }) => ({
      id,
      friendlyId,
      content,
      testCategory,
      trainingLevel,
      machineUnit,
      plcType,
      cluster,
      equipmentType,
      oem,
      question: question.text,
      professions
    })
  );

  return questionsList;
}

export function toGenerateNMrQuestions(item): QuestionViewNmrModel[] {
  const questionsList = (item || []).map(question => ({
    id: question.id,
    friendlyId: question.friendlyId,
    content: question.content,
    nmrCluster: question.nmrCluster,
    functionalArea: question.functionalArea,
    testCategory: question.testCategory,
    professions: question.professions,
    trainingName: question.trainingName,
    functionalSubArea: question.functionalSubArea,
    trainingLevel: question.trainingLevel,
    question: question.question.text
  }));

  return questionsList;
}

export function toQuestionAnswerViewModel(item: FeedbackFormQuestionAnswerDto[]): QuestionAnswerViewModel[] {
  return (item || []).map(x => ({
    answerType: x.answerType,
    isMandatory: x.isMandatory,
    question: x.question,
    questionId: x.questionId,
    questionTranslations: x.questionTranslations,
    value: x.value
  }));
}

export function toChangeFeedbackFormAnswersDto(viewModel: FeedbackFormViewModel): ChangeFeedbackFormAnswersDto {
  return {
    id: viewModel.id,
    questionAnswers: (viewModel.questionAnswers || []).map(x => ({
      questionId: x.questionId,
      value: x.value
    }))
  };
}
