import {
  EventTypeDto,
  EventTypeCategory,
  EventFieldGroups,
  EventUserRoles
} from '../stores/configuration/events-workflow/event-types-store';
import { isNullOrWhiteSpaces } from './useful-functions';

export function hasRequestDetails(eventType: EventTypeDto): boolean {
  return eventType && eventType.requestFieldGroups && eventType.requestFieldGroups.indexOf('RequestDetails') >= 0;
}

export function hasEventDetails(eventType: EventTypeDto): boolean {
  return eventType && eventType.requestFieldGroups && eventType.requestFieldGroups.indexOf('EventDetails') >= 0;
}

export function hasInstructors(eventType: EventTypeDto): boolean {
  return eventType && eventType.instructor === 'Yes';
}

export function hasStudents(eventType: EventTypeDto): boolean {
  return eventType && eventType.participants;
}

export function hasSupportDetails(eventType: EventTypeDto): boolean {
  return eventType && eventType.requestFieldGroups && eventType.requestFieldGroups.includes(EventFieldGroups.SupportDetails.toString());
}

export function getEventOwners(eventType: EventTypeDto): string[] {
  if (eventType == null) return [];
  let owners: string[] = [];

  if (eventType.roleThatOwnEvents != null) owners.push(eventType.roleThatOwnEvents);
  if ((eventType.rolesThatOwnEventsExtended || []).length !== 0) owners = [...owners, ...(eventType.rolesThatOwnEventsExtended || [])];

  return [...new Set(owners)];
}

export const isExtendedWorkflow = (eventTypeCategory: string): boolean => {
  return !isNullOrWhiteSpaces(eventTypeCategory) && EventTypeCategory[eventTypeCategory] === EventTypeCategory.Extended;
};

export function isGEA(eventType: EventTypeDto): boolean {
  return (
    eventType != null &&
    EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended &&
    eventType.requestFieldGroups.any(x => EventFieldGroups[x] === EventFieldGroups.SupportDetails) &&
    (eventType.rolesThatCreateRequests || []).length !== 0 &&
    eventType.rolesThatCreateRequests.any(x => EventUserRoles[x] === EventUserRoles.FactoryLead) &&
    eventType.rolesThatCreateRequests.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    (eventType.rolesThatValidateRequestsExtended || []).length >= 2 &&
    eventType.rolesThatValidateRequestsExtended.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    eventType.rolesThatValidateRequestsExtended.any(x => EventUserRoles[x] === EventUserRoles.GlobalEngineering)
  );
}

export function isGMA(eventType: EventTypeDto): boolean {
  return (
    eventType != null &&
    EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended &&
    eventType.requestFieldGroups.any(x => EventFieldGroups[x] === EventFieldGroups.SupportDetails) &&
    (eventType.rolesThatCreateRequests || []).length !== 0 &&
    eventType.rolesThatCreateRequests.any(x => EventUserRoles[x] === EventUserRoles.FactoryLead) &&
    eventType.rolesThatCreateRequests.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    (eventType.rolesThatValidateRequestsExtended || []).length >= 3 &&
    eventType.rolesThatValidateRequestsExtended.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    eventType.rolesThatValidateRequestsExtended.any(x => EventUserRoles[x] === EventUserRoles.GlobalManufacturing) &&
    eventType.rolesThatValidateRequestsExtended.any(x => EventUserRoles[x] === EventUserRoles.RegionalManufacturingVP)
  );
}

export function getValidators(eventType: EventTypeDto): string[] {
  if (eventType == null) return [];
  let validators: string[] = [];
  if (EventTypeCategory[eventType.eventTypeCategory] !== EventTypeCategory.Extended && eventType.roleThatValidateRequests != null)
    validators.push(eventType.roleThatValidateRequests);
  if (
    EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended &&
    (eventType.rolesThatValidateRequestsExtended || []).length !== 0
  )
    validators = [...validators, ...eventType.rolesThatValidateRequestsExtended];
  return validators;
}
