import {
  Categories,
  CreateRequestDto,
  CreateRequestMachinesDto,
  NewSupportPositions,
  RequestStatus,
  SupportDetails
} from './requests-store';
import { FormStore } from '../formStore';
import { repository } from 'redux-scaffolding-ts';
import { CommandResult } from '../types';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { ItemReference } from '../dataStore';
import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import { StudentDto } from 'stores/students/student-model';
import { DateTimeService } from 'services/datetime-service';
import { MachineListItemViewModel } from 'site/pages/shared/events-and-requests/machine-list-item';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { EventTypeCategory, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { RequestReasonEventTypeDto } from 'stores/configuration/events-n-requests/request-reasons-store';

export interface RequestWizardData {
  requestId: string;
  requestOwnerId: string;
  requestingLocationId: string;
  status: RequestStatus;

  // Step 1
  eventType: EventTypeDto;
  eventLocation: ItemReference;
  requestLocation: ItemReference;
  priority: ItemReference;
  role: ItemReference;
  category: Categories;
  isMachineRelated: boolean;
  isTechnical: boolean;
  isRequestDetails: boolean;
  isEventDetails: boolean;
  isInstructorYes: boolean;
  isParticipantYes: boolean;
  studentsMandatory: boolean;
  hasSupportDetails: boolean;
  eventsColor: string;
  requestReason: ItemReference;
  requestReasonComments: string;
  requestReasonEventTypes: RequestReasonEventTypeDto[];
  requestPreApprovedDuringASPCheck: boolean;

  // Step 2
  nmrCluster: ItemReference;
  nmrFunctionalArea: ItemReference;
  nmrFunctionalSubArea: ItemReference;
  nmrTrainingName: ItemReference;
  withPattern: boolean;
  hasModifiedPattern: boolean;
  pattern: ItemReference;
  machines: MachineListItemViewModel[];
  machineModels: CreateRequestMachinesDto[];
  plcNames: string[];
  machineRelated: boolean;

  // Step 3
  title: string;
  trainingLevel: ItemReference;
  customization: ItemReference;
  deliveryMethod: ItemReference;
  language: ItemReference;
  manualTitle: boolean;

  // Step 4
  startDate: Date;
  endDate: Date;
  desiredEventDuration: string;
  calculatedEventDuration: number;
  plannedDuration: string;
  userEventDuration: number;

  //Step 5 Support details
  supportDetails: SupportDetails;

  //Step 6
  studentsNumber: number;
  manualStudents: boolean;
  students: StudentDto[];

  // Step 7
  instructorLocation: ItemReference;
  instructor: ItemReference;

  // Step 8
  comments: string;
}

export class CreateRequestWizardValidator extends AbstractValidator<RequestWizardData> {
  constructor() {
    super();

    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));
  }
}

export class CreateRequestWizardValidatorStep1 extends AbstractValidator<RequestWizardData> {
  constructor() {
    super();

    this.validateIf(({ requestReason }) => requestReason.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Request Reason is required'));

    this.validateIf(({ requestReasonComments }) => requestReasonComments.trim())
      .isNotEmpty()
      .when(x => x.eventType && EventTypeCategory[x.eventType.eventTypeCategory] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Support Need Explanation is required'));

    this.validateIf(o => o.eventType)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIf(({ requestingLocationId, requestLocation }) => requestingLocationId || requestLocation)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Request Location is required'));

    this.validateIf(({ isRequestDetails, eventLocation }) => isRequestDetails === true && eventLocation.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t(`Event Location is required`));

    this.validateIf(({ isRequestDetails, category }) => isRequestDetails === true && category)
      .isNotEmpty()
      .withFailureMessage(i18n.t(`Category is required`));

    this.validateIf(({ isRequestDetails, role }) => isRequestDetails === true && role && role.id)
      .isNotEmpty()
      .when(x => x.eventType && EventTypeCategory[x.eventType.eventTypeCategory] !== EventTypeCategory.Extended)
      .withFailureMessage(i18n.t(`Role is required`));

    this.validateIf(({ isRequestDetails, isMachineRelated }) => isRequestDetails === true && isMachineRelated)
      .isNotEmpty()
      .withFailureMessage(i18n.t(`Machine Related is required`));
  }
}

export class CreateRequestWizardValidatorStep2 extends ExtendedAbstractValidator<RequestWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(o => o.machineModels)
      .isNotEmpty()
      .when(t => !t.withPattern)
      .withFailureMessage(i18n.t('Machine rows are required'));
    this.validateIf(o => o.pattern)
      .isNotEmpty()
      .when(t => t.withPattern)
      .withFailureMessage(i18n.t('Pattern is required.'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o =>
        (o.machineModels || []).all(
          (i, idx) => i != null && new MachineRowNoPatternHasRequiredFields(idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => !x.withPattern)
      .withFailureMessage(i18n.t('At least one machine row is invalid.'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o =>
        (o.machineModels || []).all(
          (i, idx) => i != null && new MachineRowHasRequiredFields(idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => x.withPattern)
      .withFailureMessage(i18n.t('At least one machine row is invalid.'));
  }
}

export class CreateRequestWizardValidatorNMRStep2 extends ExtendedAbstractValidator<RequestWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(o => o.nmrCluster)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIf(o => o.nmrFunctionalArea)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Functional Area is required'));
  }
}

export class MachineRowHasRequiredFields extends ExtendedAbstractValidator<CreateRequestMachinesDto> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x != null && x.machineRelatedClusterId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);
  }
}

export class MachineRowNoPatternHasRequiredFields extends ExtendedAbstractValidator<CreateRequestMachinesDto> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x != null && x.machineRelatedClusterId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);

    this.validateIfString(x => x.oemId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('OEM is required')}`);

    this.validateIfString(x => x.machineModelId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Model is required')}`);
  }
}

export class CreateRequestWizardValidatorStep3 extends AbstractValidator<RequestWizardData> {
  constructor() {
    super();

    this.validateIfString(o => o.title.trim())
      .isNotEmpty()
      .withFailureMessage(i18n.t('Request title is required'));

    this.validateIf(o => o.trainingLevel && o.trainingLevel.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIf(o => o.deliveryMethod && o.deliveryMethod.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Delivery Method is required'));
  }
}

export class CreateRequestWizardValidatorStepDates4 extends AbstractValidator<RequestWizardData> {
  constructor() {
    super();

    this.validateIfDate(o => o.startDate)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage(i18n.t('Start Date is required'));

    this.validateIfDate(o => o.endDate)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage(i18n.t('End Date is required'));

    this.validateIf(o => DateTimeService.toMoment(o.endDate).isBefore(DateTimeService.toMoment(o.startDate), 'day'))
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than End Date'));

    this.validateIf(o => o.desiredEventDuration)
      .isNotNull()
      .fulfills(x => parseFloat(x) !== 0 && !isNullOrWhiteSpaces(x))
      .withFailureMessage(i18n.t('Desired Event Duration is required'));
  }
}

class CreateRequestWizardValidatorStep5 extends ExtendedAbstractValidator<RequestWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        x =>
          x.supportDetails.totalRequestedManDays > 0 ||
          x.supportDetails.totalRequestedWorkingManHours > 0 ||
          x.supportDetails.totalRequestedManMonths > 0 ||
          x.supportDetails.totalTheoreticalCost > 0
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .withFailureMessage(i18n.t('At least one support position need Theoretical total costs'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ supportDetails: { newSupportPositions } }) =>
          (newSupportPositions || []).length > 0 && newSupportPositions.every(y => y.requestedHC < 31)
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .withFailureMessage(i18n.t('Support Positions maximum is 30'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ supportDetails: { newSupportPositions }, startDate, endDate }) =>
          (newSupportPositions || []).length > 0 &&
          (newSupportPositions || []).every((i, idx) => {
            return new NewSupportPositionValidator(
              DateTimeService.toString(startDate),
              DateTimeService.toString(endDate),
              idx,
              this.addErrors
            )
              .extendValidate(i)
              .isValid();
          })
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .withFailureMessage(i18n.t('At least one support position row is invalid.'));
  }
}
export class NewSupportPositionValidator extends ExtendedAbstractValidator<NewSupportPositions> {
  constructor(eventStart: string, eventEnd: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Support Item at position ${idx + 1}`);

    this.validateIfNumber(x => x.requestedHC)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid Requested HC')}`);

    this.validateIf(x => x.supportPositionRoleId)
      .isNotNull()
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid Role')}`);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ machineModels }) =>
          (machineModels || []).length > 0 &&
          (machineModels || []).every((m, machinesIdx) => {
            return new NewSupportPositionMachinesValidator(idx, machinesIdx, this.addErrors).extendValidate(m).isValid();
          })
      )
      .withFailureMessage(`${preffix}: ${i18n.t('At least one support position row is invalid.')}`);

    this.validateIf(x => x.startDate)
      .isNotNull()
      .isDefined()
      .fulfills(start => DateTimeService.toMoment(eventStart).isSameOrBefore(DateTimeService.toMoment(start), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Period Date From is not in the event range')}`);

    this.validateIf(x => x.endDate)
      .isNotNull()
      .isDefined()
      .fulfills(end => DateTimeService.toMoment(eventEnd).isSameOrAfter(DateTimeService.toMoment(end), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Period Date To is not in the event range')}`);

    this.validateIfNumber(x => x.theoreticalCost)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid Theoretical cost')}`);

    this.validateIfNumber(x => x.requestedManDays)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid Requested Man Days')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ machineModels }) => {
        const machineIds = [];
        machineModels.map(x => {
          machineIds.push(x.machineModelId);
          return null;
        });
        const duplicatedMachines = (machineIds || []).find((item, index) => machineIds.indexOf(item) !== index);
        return duplicatedMachines == null;
      })
      .withFailureMessage(`${preffix}: ${i18n.t('There are repeated machines')}`);
  }
}

export class NewSupportPositionMachinesValidator extends ExtendedAbstractValidator<CreateRequestMachinesDto> {
  constructor(idxGeneral: number, idxMachine: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Machine at position ${idxGeneral + 1} - ${idxMachine + 1}`);

    this.validateIf(x => x.machineRelatedClusterId)
      .isNotNull()
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine Cluster')}`);

    this.validateIf(x => x.equipmentTypeId)
      .isNotNull()
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine Equipment Type')}`);

    this.validateIf(x => x.oemId)
      .isNotNull()
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine OEM')}`);

    this.validateIf(x => x.machineModelId)
      .isNotNull()
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine Model')}`);
  }
}

export class CreateRequestWizardValidatorStudentsStep6 extends AbstractValidator<RequestWizardData> {
  constructor() {
    super();
    this.validateIfNumber(t => t.studentsNumber - t.students.length)
      .isGreaterThanOrEqual(0)
      .when(x => x.isParticipantYes && x.studentsMandatory)
      .withFailureMessage(i18n.t('Students Assigned must be greater or equal than the number of student added'));

    this.validateIfNumber(o => o.studentsNumber)
      .isNotEmpty()
      .isGreaterThan(0)
      .when(x => x.isParticipantYes && x.studentsMandatory)
      .withFailureMessage(i18n.t('Students are required'));
  }
}

export class CreateRequestWizardValidatorInstructorStep7 extends AbstractValidator<RequestWizardData> {
  constructor() {
    super();
    this.validateIf(o => o.instructor && o.instructor.id)
      .isNotEmpty()
      .when(x => x.instructorLocation != null)
      .withFailureMessage(i18n.t("Instructor's Name is required"));
  }
}

@repository('@@REQUESTS', 'requests.creation-wizard')
export class RequestWizardStore extends FormStore<RequestWizardData> {
  baseUrl = `events/v1`;
  createPath = 'new-request';
  retrievePath = '';
  updatePath = '';

  protected validate(item: RequestWizardData) {
    return new CreateRequestWizardValidator().validate(item);
  }

  public validateBasicStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorStep1().validate(item);
  }

  public validateSubjectStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorStep2().extendValidate(item);
  }

  public validateNMRSubjectStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorNMRStep2().extendValidate(item);
  }

  public validateEventDetailsStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorStep3().validate(item);
  }

  public validateDatesStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorStepDates4().validate(item);
  }

  public validateSupportDetailsStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorStep5().extendValidate(item);
  }

  public validateStudentsStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorStudentsStep6().validate(item);
  }

  public validateInstructorStep(item: RequestWizardData) {
    return new CreateRequestWizardValidatorInstructorStep7().validate(item);
  }

  public validateCommentsStep(item: RequestWizardData) {
    return new CreateRequestWizardValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENT', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  public async submitWizzard() {
    const validationResult = this.validate(this.state.item);
    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
    } else {
      const httpService = container.get(HttpService);
      let result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<CreateRequestDto, CommandResult<CreateRequestDto>>(
          `${this.baseUrl}/${this.createPath}`,
          this.mapWizardDataToCreateRequestDto()
        )
      );
      return result.data;
    }
  }

  private mapWizardDataToCreateRequestDto(): CreateRequestDto {
    const { item } = this.state;
    const createRequestDto: CreateRequestDto = {
      customizationDataId: item.customization ? item.customization.id : null,
      customizationDataName: item.customization ? item.customization.title : null,
      category: item.category,
      comments: item.comments,
      deliveryMethodId: item.deliveryMethod ? item.deliveryMethod.id : null,
      deliveryMethodName: item.deliveryMethod ? item.deliveryMethod.title : null,
      desiredEventDuration: item.desiredEventDuration,
      endDate: item.endDate,
      eventLocationId: item.eventLocation ? item.eventLocation.id : null,
      eventLocationName: item.eventLocation ? item.eventLocation.title : null,
      eventTypeId: item.eventType ? item.eventType.id : null,
      eventTypeName: item.eventType ? item.eventType.title : null,
      eventLocationCountryName: null,
      eventTypeRequestDetails: [],
      eventsColor: item.eventsColor ? item.eventsColor : null,
      instructorId: item.instructor ? item.instructor.id : null,
      instructorName: item.instructor ? item.instructor.title : null,
      instructorLocationCountryName: null,
      instructorLocationId: item.instructorLocation ? item.instructorLocation.id : null,
      instructorLocationName: item.instructorLocation ? item.instructorLocation.title : null,
      isEventDetails: item.isEventDetails,
      isCanceled: false,
      isInstructorYes: item.isInstructorYes,
      isMachineRelated: item.isMachineRelated,
      isRequestDetails: item.isRequestDetails,
      languageId: item.language ? item.language.id : null,
      languageName: item.language ? item.language.title : null,
      machineModelName: null,
      nmrClusterId: item.nmrCluster ? item.nmrCluster.id : null,
      nmrClusterName: item.nmrCluster ? item.nmrCluster.title : null,
      languageCode: null,
      nmrFunctionalAreaId: item.nmrFunctionalArea ? item.nmrFunctionalArea.id : null,
      nmrFunctionalAreaName: item.nmrFunctionalArea ? item.nmrFunctionalArea.title : null,
      nmrFunctionalSubAreaId: item.nmrFunctionalSubArea ? item.nmrFunctionalSubArea.id : null,
      nmrFunctionalSubAreaName: item.nmrFunctionalSubArea ? item.nmrFunctionalSubArea.title : null,
      nmrTrainingNameId: item.nmrTrainingName ? item.nmrTrainingName.id : null,
      nmrTrainingNameName: item.nmrTrainingName ? item.nmrTrainingName.title : null,
      patternId: item.pattern ? item.pattern.id : null,
      patternName: item.pattern ? item.pattern.title : null,
      plcTypeName: null,
      priorityId: item.priority ? item.priority.id : null,
      priorityName: item.priority ? item.priority.title : null,
      requestOwnerId: item.requestOwnerId,
      trainingLevelId: item.trainingLevel ? item.trainingLevel.id : null,
      trainingLevelName: item.trainingLevel ? item.trainingLevel.title : null,
      title: item.title,
      requestMachines: item.machineModels,
      requestReasonId: item.requestReason ? item.requestReason.id : null,
      requestReasonComments: item.requestReasonComments,
      requestingCountryName: null,
      requestingLocationId: item.requestingLocationId,
      requestingLocationName: null,
      roleId: item.role ? item.role.id : null,
      roleName: item.role ? item.role.title : null,
      startDate: item.startDate,
      status: item.status,
      students: (item.students || []).map(x => x.id),
      studentsNumber: item.studentsNumber,
      requestId: item.requestId,
      supportDetails: item.supportDetails ? item.supportDetails : null
      //,requestPreApprovedDuringASPCheck: item.requestPreApprovedDuringASPCheck
    };

    return createRequestDto;
  }
}
