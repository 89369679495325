import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { DataStore } from 'stores/dataStore';
import { repository } from 'redux-scaffolding-ts';
import { EmptyValidator } from './feedbacks-templates-store';
import { ProfessionDto } from 'stores/configuration/profiles/professions-store';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { EquipmentTypeDto } from 'stores/configuration/machinery/equipment-types-store';
import { OemDto } from 'stores/configuration/machinery/oems-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import { NMFunctionalAreaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { NMTrainingNameDto } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { NMFunctionalSubareaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { TheoreticalQuestionMRItemDto, NmrTheoreticalQuestionItemDto } from '../questionBank/theoretical-test-store';
import { EventStatus } from 'stores/events/events-store';
import { FormStore } from 'stores/formStore';
import { ProfileItemDto } from 'stores/profile/profile-store';

export type RelatedToType = 'Event' | 'TNA';

export enum TemplateEventStatus {
  InProgress = 10,
  Completed = 20
}

export interface TheoreticalTemplateItemDto {
  id: string;
  friendlyId: string;
  title: string;
  header: string;
  isActive: boolean;
  isMachineRelated: boolean;
  profileId: string;
  profile: ProfileItemDto;
  profession: ProfessionDto;
  professionItemId: string;
  typeRelated: RelatedToType;
  status: EventStatus;
  hoursDuration: number;
  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
  theoreticalTemplateMRDetails: TheoreticalTemplateMrDetailsDto;
  nmrTheoreticalTemplateDetails: TheoreticalTemplateNmrDetailsDto;
}

export interface TheoreticalTemplateNmrDetailsDto {
  nmrClusterId: string;
  nmrCluster: NMClusterDto;
  functionalAreaId: string;
  functionalArea: NMFunctionalAreaDto;
  trainingNameId: string;
  trainingName: NMTrainingNameDto;
  functionalSubareaId: string;
  functionalSubArea: NMFunctionalSubareaDto;
  nmrTheoreticalQuestions: NmrTheoreticalQuestionItemDto[];
}

export interface TheoreticalTemplateMrDetailsDto {
  clusterId: string;
  cluster: ClusterDto;
  equipmentTypeId: string;
  equipmentType: EquipmentTypeDto;
  oemId: string;
  oem: OemDto;
  machineModelId: string;
  machineModel: MachineModelDto;
  theoreticalQuestionsMR: TheoreticalQuestionMRItemDto[];
}

@repository('@@THEORETICALTEMPLATES', 'theoretical-templates.summary')
export class TheoreticalTemplatesStore extends DataStore<TheoreticalTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = 'get-theoretical-templates';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'get-theoretical-template';

  protected validate(item: TheoreticalTemplateItemDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('THEORETICALTEMPLATE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getTemplateById(id: string): Promise<TheoreticalTemplateItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<TheoreticalTemplateItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

export interface CreateTheoreticalTemplateItemDto {
  title: string;
  header: string;
  isActive: boolean;
  isMachineRelated: boolean;
  professionItemId: string;
  profileId: string;
  typeRelated: RelatedToType;
  status: EventStatus;
  hoursDuration: number;
  trainingLevelId: string;
  theoreticalTemplateMRDetails: CreateTheoreticalTemplateMrDetailsDto;
  nmrTheoreticalTemplateDetails: CreateTheoreticalTemplateNmrDetailsDto;
}
export interface CreateTheoreticalTemplateMrDetailsDto {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  theoreticalQuestionsMR: string[];
}

export interface CreateTheoreticalTemplateNmrDetailsDto {
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string;
  functionalSubareaId: string;
  nmrTheoreticalQuestions: string[];
}

@repository('@@THEORETICALTEMPLATES', 'theoretical-templates.new')
export class NewTheoreticalTemplatesStore extends FormStore<CreateTheoreticalTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-template';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateTheoreticalTemplateItemDto) {
    return new EmptyValidator().validate(item);
  }

  constructor() {
    super('NEW_THEORETICALTEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

export interface ChangeTheoreticalTemplateItemDto {
  id: string;
  title: string;
  header: string;
  isActive: boolean;
  isMachineRelated: boolean;
  professionItemId: string;
  typeRelated: RelatedToType;
  status: EventStatus;
  hoursDuration: number;
  trainingLevelId: string;
  theoreticalTemplateMRDetails: ChangeTheoreticalTemplateMrDetailsDto;
  nmrTheoreticalTemplateDetails: ChangeTheoreticalTemplateNmrDetailsDto;
}

export interface ChangeTheoreticalTemplateMrDetailsDto {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  theoreticalQuestionsMR: string[];
}

export interface ChangeTheoreticalTemplateNmrDetailsDto {
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string;
  functionalSubareaId: string;
  nmrTheoreticalQuestions: string[];
}

@repository('@@THEORETICALTEMPLATES', 'theoretical-templates.change')
export class ChangeTheoreticalTemplatesStore extends FormStore<ChangeTheoreticalTemplateItemDto> {
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = '';
  updatePath = 'update-theoretical-template';

  protected validate(item: ChangeTheoreticalTemplateItemDto) {
    return new EmptyValidator().validate(item);
  }

  constructor() {
    super('CHANGE_THEORETICALTEMPLATE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
