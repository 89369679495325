import { repository } from 'redux-scaffolding-ts';
import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { FormStore } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import i18n from 'i18n';
import { ItemReference } from 'stores/dataStore';
import { SimpleUserDto } from 'stores/users/users-store';

export interface FeedbackFormAnswersDto {
  id: string;
  answered: boolean;
  userId: string;
  user: SimpleUserDto;
  formId: string;
  form: SimpleFeedbackFormDto;
  questionAnswers: FeedbackFormQuestionAnswerDto[];
}

export interface SimpleFeedbackFormDto {
  id: string;
  eventId: string;
  eventTitle: string;
  eventFriendlyId: string;
  feedbackTemplateId: string;
  feedbackTemplateName: string;
  feedbackTemplateHeader: string;
}
export interface FeedbackFormQuestionAnswerDto {
  questionId: string;
  question: QuestionDto;
  answerType: AnswerTypes;
  questionTranslations: QuestionDto[];
  value: string;
  isMandatory: boolean;
}

export interface ChangeFeedbackFormQuestionAnswerDto {
  questionId: string;
  value: string;
}

export interface ChangeFeedbackFormAnswersDto {
  id: string;
  questionAnswers: ChangeFeedbackFormQuestionAnswerDto[];
}

export enum AnswerTypes {
  Unknown = 0,
  YesOrNo = 10,
  Text = 20,
  Rating = 30
}

export interface QuestionDto {
  text: string;
  subtitle: string;
  languageId: string;
  language: LanguageDto;
}

export class QuestionAnswerViewModel {
  questionId: string;
  question: QuestionDto;
  questionTranslations: QuestionDto[];
  answerType: AnswerTypes;
  value: string;
  isMandatory: boolean;
}

export class FeedbackFormViewModel {
  id: string;
  answered: boolean;
  userId: string;
  userFirstName: string;
  userLastName: string;

  eventTitle: string;
  eventFriendlyId: string;

  feedbackTemplateName: string;
  feedbackTemplateHeader: string;

  questionAnswers: QuestionAnswerViewModel[];

  readOnly: boolean;
  selectedLanguage: ItemReference;
}

class QuestionAnswerViewModelValidator extends ExtendedAbstractValidator<QuestionAnswerViewModel> {
  constructor(index: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Invalid answer ${index + 1}`);

    this.validateIf(x => x.value)
      .isNotNull()
      .isDefined()
      .when(x => x.isMandatory)
      .withFailureMessage(`${preffix}: ${i18n.t('You must answer the question')}`);
  }
}

class FeedBackFormViewModelValidator extends ExtendedAbstractValidator<FeedbackFormViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.questionAnswers || []).all(
          (question, index) =>
            question != null && new QuestionAnswerViewModelValidator(index, this.addErrors).extendValidate(question).isValid()
        )
      )
      .withFailureMessage(i18n.t('At least one question is wrong'));
  }
}

@repository('@@FEEDBACKFORMS', 'feedback-forms.answers')
export class FeedBackFormViewModelStore extends FormStore<FeedbackFormViewModel> {
  RETRIEVE_ONE_FEEDBACK_FORM_ANSWER = 'RETRIEVE_ONE_FEEDBACK_FORM_ANSWER';

  baseUrl = 'events';
  createPath = '';
  retrievePath = '';
  updatePath = '';
  deletePath = '';
  hardDeletePath = '';
  retrieveOnePath = 'v1/get-feedback-form-answers';
  sendFeedbackFormPath = 'v1/send-feedback-form-answers';

  constructor() {
    super('FEEDBACKFORMS', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  protected validate(item: FeedbackFormViewModel): ValidationResult {
    return new FeedBackFormViewModelValidator().extendValidate(item);
  }

  public async getFeedbackFormAnswers(formAnwersId: string): Promise<FeedbackFormAnswersDto> {
    const httpService = container.get(HttpService);

    const result = await httpService.get<FeedbackFormAnswersDto>(`${this.baseUrl}/${this.retrieveOnePath}/${formAnwersId}`);
    return result.data;
  }

  public async sendFeedbackFormAnswers(): Promise<FeedbackFormAnswersDto> {
    let { item } = this.state;
    const sendFeedbackFormValidation = this.validate(item);

    if (sendFeedbackFormValidation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, sendFeedbackFormValidation);
    } else {
      let changeFeedbackFormAnswersDto = toChangeFeedbackFormAnswersDto(item);
      const httpService = container.get(HttpService);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<ChangeFeedbackFormAnswersDto, FeedbackFormAnswersDto>(
          `${this.baseUrl}/${this.sendFeedbackFormPath}`,
          changeFeedbackFormAnswersDto
        )
      );
      return result.data;
    }
  }
}

export function toViewModel(item: FeedbackFormAnswersDto): FeedbackFormViewModel {
  if (!item) return null;

  return {
    id: item.id,
    answered: item.answered,
    eventFriendlyId: item.form && item.form.eventFriendlyId,
    eventTitle: item.form && item.form.eventTitle,
    feedbackTemplateHeader: item.form && item.form.feedbackTemplateHeader,
    feedbackTemplateName: item.form && item.form.feedbackTemplateName,
    questionAnswers: toQuestionAnswerViewModel(item.questionAnswers),
    userFirstName: item.user && item.user.firstName,
    userLastName: item.user && item.user.lastName,
    userId: item.userId,
    readOnly: true,
    selectedLanguage: null
  };
}

export function toQuestionAnswerViewModel(item: FeedbackFormQuestionAnswerDto[]): QuestionAnswerViewModel[] {
  return (item || []).map(x => ({
    answerType: x.answerType,
    isMandatory: x.isMandatory,
    question: x.question,
    questionId: x.questionId,
    questionTranslations: x.questionTranslations,
    value: x.value
  }));
}

export function toChangeFeedbackFormAnswersDto(viewModel: FeedbackFormViewModel): ChangeFeedbackFormAnswersDto {
  return {
    id: viewModel.id,
    questionAnswers: (viewModel.questionAnswers || []).map(x => ({
      questionId: x.questionId,
      value: x.value
    }))
  };
}
